import React, { useEffect, useState } from "react";
import AutoIncrementLeaveDetails from "./AutoIncrementLeaveDetails";
import AddAutoIncrementLeaveBalance from "./AddAutoIncrementLeaveBalance";
import { getCurrentMonthNames } from "../../../../utils/FormatDate/formaDateInMonths";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const LeaveBalanceAutoIncrementList = ({ selectedLeaveType, selectedYear }) => {

  const axiosPrivate = useAxiosPrivate();
  const months = getCurrentMonthNames();
  const [details, setDetails] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  
  const fetchDetails = async () => {
    try {
      if (!selectedLeaveType.id) return;
      const response = await axiosPrivate.get(
        `lms/leave/viewDetailsAutoIncrementLeaveBalance?leave_type_id=${selectedLeaveType.id}&year=${selectedYear}`
      );
      const data = response?.data?.leaveTypeList || [];
      setDetails(data);
    } catch (error) {
      // console.error("Error fetching data: ", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDetails();
  }, [selectedLeaveType]);

  const toggleModal = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  return (
    <div className="relative overflow-x-auto min-h-screen bg-white shadow-md rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 text-gray-700 border-b border-gray-200 tracking-wider">
          <tr>
            <th scope="col" className="px-6 py-3">
              <span>Month</span>
            </th>
            <th scope="col" className="px-6 py-3">
              <span>Year</span>
            </th>
            <th scope="col" className="px-6 py-3">
              <span>Date</span>
            </th>
            <th scope="col" className="px-6 py-3">
              <span>Time</span>
            </th>
            <th scope="col" className="px-6 py-3">
              <span>Updated Type</span>
            </th>
            <th scope="col" className="px-6 py-3">
              <span>Status</span>
            </th>
          
            <th scope="col" className="px-6 py-3"></th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <AutoIncrementLeaveDetails
            details={details}
            months={months}
            leaveTypeId={selectedLeaveType.id}
            leaveTypeName={selectedLeaveType.name}
            refreshDetails={fetchDetails}
          />
        </tbody>
      </table>
      {isModalOpen && (
        <div className="modal">
          <AddAutoIncrementLeaveBalance
            open={isModalOpen}
            close={toggleModal}
            leaveTypeId={selectedLeaveType.id}
            leaveTypeName={selectedLeaveType.name}
            refreshDetails={fetchDetails}
          />
        </div>
      )}
    </div>
  );
};

export default LeaveBalanceAutoIncrementList;
