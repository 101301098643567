
const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  
  export const getCurrentMonthDates = () => {
    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    const formattedFirstDay = formatDate(firstDay);
    const formattedLastDay = formatDate(lastDay);
    return { firstDay: formattedFirstDay, lastDay: formattedLastDay };
  };
  
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${day} ${month} ${year}`;
  };
  
  export const getCurrentMonthNames = () => {
    const currentMonthIndex = new Date().getMonth();
    return monthNames.slice(0, currentMonthIndex + 1);
  };

  export const getMonthName = (monthIndex) => {
    return monthNames[monthIndex - 1]; 
  };
  export const isFinalIncrementPeriodOfMonth = () => {   
    const today = new Date();
    const todayDate = today.getDate();
    return todayDate >= 28; // Last week starts on 28th
  };
  export const formatHourAndMinute = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };
  export const getFormattedFullDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };


  export const formatTimeStamp = (timeString) => {
    const date = new Date(timeString);  
  
    const hours = date.getUTCHours().toString().padStart(2, '0');  
    const minutes = date.getUTCMinutes().toString().padStart(2, '0');  
    const seconds = date.getUTCSeconds().toString().padStart(2, '0'); 
  
    return `${hours}:${minutes}:${seconds}`;  
  };

  export const formatTime = (timeString) => {
    if (!timeString) return "-";
  
    const timeParts = timeString.match(/(\d{1,2}):(\d{2}):(\d{2})\.(\d+)/);
    if (!timeParts) return "-";
  
    let [_, hoursStr, minutes, seconds, milliseconds] = timeParts;
    let hours = parseInt(hoursStr, 10);
    minutes = parseInt(minutes, 10);
    seconds = parseInt(seconds, 10);
    milliseconds = parseInt(milliseconds, 10);
    if (isNaN(hours) || isNaN(minutes) || isNaN(seconds) || isNaN(milliseconds)) {
      return "-";
    }
    if (milliseconds >= 500) {
      seconds += 1;
    }
    if (seconds === 60) {
      seconds = 0;
      minutes += 1;
    }  
    if (minutes === 60) {
      minutes = 0;
      hours += 1;
    }
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")} ${ampm}`;
  };
  
  export const calculateTimeDifference = (startTime, endTime) => {
    if (!startTime || !endTime) return "-";
  
    const parseTime = (timeString) => {
      const timeParts = timeString.match(/(\d{1,2}):(\d{2}):(\d{2})\.(\d+)/);
      if (!timeParts) return null;
  
      const [_, hoursStr, minutes, seconds, milliseconds] = timeParts;
      let hours = parseInt(hoursStr, 10);
      const ampm = timeString.includes("PM") ? "PM" : "AM";
  
      if (ampm === "PM" && hours !== 12) hours += 12;
      if (ampm === "AM" && hours === 12) hours = 0;
  
      return new Date(0, 0, 0, hours, minutes, seconds, milliseconds);
    };
  
    const startDate = parseTime(startTime);
    const endDate = parseTime(endTime);
  
    if (!startDate || !endDate) return "-";
    let diffInMilliseconds = endDate - startDate;
    if (diffInMilliseconds < 0) {
      diffInMilliseconds += 24 * 60 * 60 * 1000; 
    } 
    const diffInSeconds = diffInMilliseconds / 1000;
 
    return `${diffInSeconds.toFixed(2)} seconds`;
  };
  
  
  