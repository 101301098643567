import React from "react";

const FormFeildInfo = ({ info = "" }) => {
  return (
    <div
      className="p-2 text-xs text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
      role="alert"
    >
      <span className="font-medium">Info: </span>{info}
    </div>
  );
};

export default FormFeildInfo;
