import React, { useState } from "react";
import { viewIcon } from "../../../Icons/Icons";

import { getFormattedFullDate, formatTimeStamp, getMonthName } from "../../../utils/FormatDate/formaDateInMonths";

const LeaveAutoIncrementCornTable = ({ employeeList, onViewDetails, onRetry }) => {
  const [modalData, setModalData] = useState(null);

  const headerCol = [
    { colName: "Employee Name", key: "employee_name" },
    { colName: "Date", key: "date" },
    { colName: "Start Time", key: "start_time" },
    { colName: "End Time", key: "end_time" },
    { colName: "Increment Status", key: "status_type" },
    { colName: "Action Type", key: "updated_type" },
    { colName: "Additional Details", key: "action_type" },
  ];

  const getStatusClass = (status) => {
    switch (status) {
      case "In-Process":
        return "status status--yellow";
      case "Success":
        return "status status--green";
      case "Failed":
        return "status status--red";
      case "Cancelled":
        return "status status--yellow";
      default:
        return "status status--default";
    }
  };

  const handleViewClick = (details) => {
    // Parse the action_type string into an object
    let parsedActionType = {};
    try {
      parsedActionType = details.action_type;
    } catch (error) {
      console.error("Error parsing action_type:", error);
    }
  
    const combinedActions = [{
      step: parsedActionType.step || "N/A",
      status: parsedActionType.status || "N/A",
      message: parsedActionType.message || "No message provided",
      error: parsedActionType.Error || "No error details",
    }];
  
    const modalDetails = {
      leaveName: details.leave_types.join(", "),
      additionalDetails: combinedActions,
      month: getMonthName(details.month) || "N/A",
    };
  
    setModalData(modalDetails);
  };

  const closeModal = () => {
    setModalData(null);
  };

  return (
    <div className="relative overflow-x-auto">
      <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            {headerCol.map((col, index) => (
              <th
                scope="col"
                className={`px-4 py-3 ${col.key === "employee_name" ? "text-left" : "text-center"}`}
                key={index}
              >
                {col.colName}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {employeeList.map((val, index) => (
            <tr key={index} className="bg-white hover:bg-gray-50" style={{ border: "none" }}>
              {headerCol.map((header, headerIndex) => (
                <td
                  className={`px-4 py-3 ${header.key === "employee_name" ? "text-left" : "text-center"}`}
                  key={headerIndex}
                >
                  {header.key === "date" ? (
                    getFormattedFullDate(val[header.key])
                  ) : header.key === "start_time" || header.key === "end_time" ? (
                    formatTimeStamp(val[header.key])
                  ) : header.key === "status_type" ? (
                    <span className={getStatusClass(val[header.key])}>
                      {val[header.key] || "-"}
                    </span>
                  ) : header.key === "action_type" ? (
                    <div className="flex items-center justify-center">
                      <button
                        className="text-blue-600 flex items-center space-x-2 hover:underline"
                        onClick={() => handleViewClick(val)}
                      >
                        {viewIcon}
                      </button>
                    </div>
                  ) : (
                    val[header.key] || "-"
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {modalData && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-1/3 shadow-lg">
            <h3 className="text-lg font-medium text-gray-700 mb-4">Additional Details</h3>
            <div>
              <p>
                <strong>Leave Types:</strong> {modalData.leaveName}
              </p>
              <p>
                <strong>Incremented Month:</strong> {modalData.month}
              </p>

              {modalData.additionalDetails.map((action, index) => (
                <div key={index} className="mb-4">
                  <p><strong>Action:</strong> {action.step || "N/A"}</p>
                  <p><strong>Status:</strong> {action.status || "N/A"}</p>
                  <p><strong>Details:</strong> {action.message || "No additional details available"}</p>
                </div>
              ))}
            </div>

            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaveAutoIncrementCornTable;
