import React, { useRef, useState, useEffect } from "react";
import ReactSlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Line from "../../../Line/Line";
import { closeIcon } from "../../../../Icons/Icons";
import Select, { components } from "react-select";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import useFetchFile from "../../../../hooks/useFetchFile";
import {
  LEAVE_EXCEL_TEMPLATES,
  LEAVE_EXCEL_TEMPLATES_URL,
} from "../../../../Consts/filePathConst";
import { id } from "date-fns/locale";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import {
  useAddNewHoliday,
  useEditHolidayData,
} from "../../../../queries/holidayQuries";
import { useGetYearListData } from "../../../../queries/leaveQueries";
import {
  useGetCountryList,
  useGetStateList,
} from "../../../../queries/employeeQueries";
import { holidayTypeOptions } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import LoadingButton from "../../../Buttons/LoadingButton";
const AddorEditHoliday = ({
  open,
  close,
  handleMakeApiCallToFetchHolidayList,
  existingHolidayData = {},
}) => {
  const DropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <span className="ico-down text-blue-600"></span>
    </components.DropdownIndicator>
  );
  // console.log("existingHolidayData:",existingHolidayData)
  const [isUploading, setIsUploading] = useState(false);
  const holidayData = existingHolidayData;
  // console.log("holidayData", holidayData);
  const axiosPrivate = useAxiosPrivate();
  const [selectedCountry, setSelectedCountry] = useState(null);

  // const { data: yearListData } = useGetYearListData();
  // // console.log("year list",yearListData)

  // const yearOptions =
  //   yearListData?.data?.map((year) => ({
  //     value: year.id,
  //     label: year.year,
  //   })) || [];

  const { data: countryList } = useGetCountryList();

  const { data: stateList, isLoading: isLoadingStates } =
    useGetStateList(selectedCountry);
  const countryOptions =
    countryList?.map((country) => ({
      label: country.flag,
      value: country.id,
    })) || [];
  useEffect(() => {
    if (holidayData?.country_id) {
      setSelectedCountry(holidayData.country_id);
    }
  }, [holidayData]);

  const stateOptions =
    stateList?.map((state) => ({
      label: state.state_name,
      value: state.id,
    })) || [];

  const formattedDate = holidayData?.date ? holidayData.date.split("T")[0] : "";
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    mode: "onBlur",
    defaultValues: {
      holiday_name: holidayData?.holiday_name || "",
      date: formattedDate || "",
      country_code: holidayData?.country_id || "",
      state_code: holidayData?.state_id || "",
      holiday_type: holidayData?.holiday_type_id || "",
      year: holidayData?.year_id ? Number(holidayData.year_id) : null,
    },
  });

  const uploadFileErrorList = useRef();
  const fileInputRef = useRef(null);

  const { mutate: addHolidayMutate } = useAddNewHoliday();
  const { mutate: editHolidayMutate } = useEditHolidayData();

  const onSubmit = async (formData) => {
    try {
      const payload = {
        holiday_name: formData?.holiday_name,
        date: formData?.date,
        country_code: formData?.country_code || null,
        state_code: formData?.state_code || null,
        holiday_type: formData?.holiday_type || null,
        year: formData?.date ? new Date(formData.date).getFullYear() : null,
      };
  
      if (holidayData?.holiday_id) {
        editHolidayMutate(
          { id: holidayData?.holiday_id, ...payload },
          {
            onSuccess: (response) => {
              if (response?.status) {
                toast.success(response?.message || "Holiday updated successfully!");
                close();
              } else {
                toast.error(response?.message || "Failed to update the holiday.");
          
              }
            },
            onError: (error) => {
              toast.error(error?.response?.data?.message  || "An error occurred while updating the holiday.");
      
            },
          }
        );
      } else {
        addHolidayMutate(payload, {
          onSuccess: (response) => {
            if (response?.status) {
              toast.success(response?.message || "New holiday has been inserted successfully!");
              close();
            } else {
              toast.error("Failed to add the holiday.");
   
            }
          },
          onError: (error) => {
            toast.error(error?.response?.data?.message || "An error occurred while adding the holiday.");
  
          },
        });
      }
    } catch (error) {
      console.error("Unexpected Error:", error);
      toast.error("An unexpected error occurred.");
    }
  };
  

  const formatUploadFileErrorMessages = (err) => {
    let errmsg = "";
    if (Array.isArray(err)) {
      const ul = document.createElement("ul");
      ul.style.maxHeight = "160px";
      ul.style.overflowY = "scroll";
      ul.style.border = "1px solid #00000040";
      ul.style.padding = "4px";
      ul.style.borderRadius = "5px";
      err.forEach((item) => {
        const li = document.createElement("li");
        li.style.paddingTop = "4px";
        li.style.paddingBottom = "4px";
        li.style.display = "flex";
        const span = document.createElement("span");
        span.style.marginRight = "4px";
        span.innerHTML = "&#8226;";
        li.appendChild(span);
        const p = document.createElement("p");
        p.innerText = item;
        li.appendChild(p);
        ul.appendChild(li);
      });

      errmsg = ul.outerHTML;
    } else if (typeof err === "string") {
      errmsg = `<ul><li className="py-1"><span className="mr-1">&#8226;</span>${err}</li></ul>`;
    }
    fileInputRef.current.value = null;
    uploadFileErrorList.current.innerHTML = errmsg;
  };

  const uploadHolidayListExcelFile = async (uploadedFile) => {
    if (!uploadedFile) {
      return formatUploadFileErrorMessages(
        "No file uploaded. Please upload a valid file or download the template."
      );
    }
    setIsUploading(true);
    try {
      const formData = new FormData();
      formData.append("holiday-list", uploadedFile);

      const response = await axiosPrivate.post(
        "lms/holiday/upload/holiday-list",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response?.status) {
        toast.success(response?.data?.message || "Uploaded successfully");
        handleMakeApiCallToFetchHolidayList();
        close();
        return;
      } else {
        const errResponseMsg = response?.response?.data?.message;
        formatUploadFileErrorMessages(errResponseMsg);
      }
    } catch (err) {
      toast.error(
        "Error while uploading the holiday list file, please try again."
      );
      const errResponseMsg = err?.response?.data?.message;
      formatUploadFileErrorMessages(errResponseMsg);
    } finally {
      setIsUploading(false);
    }
  };

  const validateUploadedExcelFile = (e) => {
    uploadFileErrorList.current.innerHTML = "";
    const uploadedFile = e?.target?.files[0];
    if (!uploadedFile) {
      return formatUploadFileErrorMessages("No file uploaded");
    }
    const fileType = uploadedFile.type;
    const allowedFileTypes = [
      "text/csv",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    ];
    if (!allowedFileTypes.includes(fileType)) {
      return formatUploadFileErrorMessages(
        "Invalid file uploaded. Please upload a file with one of the following extensions: .csv, .xls, or .xlsx."
      );
    }
    uploadHolidayListExcelFile(uploadedFile);
  };

  const fetchFile = useFetchFile();

  const downloadUploadHolidayListTemplate = async () => {
    try {
      const file = await fetchFile(
        `${LEAVE_EXCEL_TEMPLATES_URL}/upload_holiday_list_template.csv`
      );
      const link = document.createElement("a");
      link.href = file;
      link.download = "upload_holiday_list_template.csv";
      link.click();
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <ReactSlidingPane
      className="add-new-holiday-modal"
      isOpen={open}
      width="480px"
      onRequestClose={close}
    >
      <div className="px-6 py-3 text-black flex justify-between items-center">
        <p className="font-semibold text-base">
          {existingHolidayData.holiday_id ? "Edit Holiday" : "Add Holiday"}
        </p>
        <span className="cursor-pointer" onClick={close}>
          {closeIcon}
        </span>
      </div>
      <Line />

      {!existingHolidayData.holiday_id && (
        <>
          <div className="px-6 py-3 flex justify-between gap-4">
            {isUploading && <LoadingButton />}
            <label htmlFor="upload-holiay-list" className="btn btn--border">
              <span>
                <i className="ico-upload mr-1"></i>
              </span>
              Upload holiday list
              <input
                id="upload-holiay-list"
                hidden
                type="file"
                onChange={validateUploadedExcelFile}
                accept=".csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                ref={fileInputRef}
              />
            </label>

            <button
              onClick={downloadUploadHolidayListTemplate}
              className="btn btn--border"
            >
              <span>
                <i className="ico-download mr-1"></i>
              </span>
              Download template
            </button>
          </div>
          <div
            className="text-red-600 px-6 py-3"
            ref={uploadFileErrorList}
            id="upload-file-errors"
          ></div>
          <div className="text-center relative justify-center py-4">
            <span className="text-black absolute z-10 top-1 left-[220px] font-medium bg-white px-3">
              OR
            </span>
            <Line />
          </div>
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="px-6 flex flex-col justify-between">
            <div>
              <div className="mb-4">
                <label>
                  Name<span className="text-red-700">*</span>
                </label>
                <input
                  {...register("holiday_name", {
                    required: "This field is required",
                  })}
                  type="text"
                  className="mt-1"
                />
                {errors?.holiday_name && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.holiday_name.message}
                  </span>
                )}
              </div>
              {/* <div className="mb-4">
                <label>Year</label>
                {isLoadingYear ? (
                  <span>Year loading...</span>
                ) : (
                  <Controller
                    control={control}
                    name="year_id"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        components={{ DropdownIndicator }}
                        className="custom-select-container"
                        onChange={(selectedOption) =>
                          onChange(selectedOption?.value || null)
                        }
                        value={
                          yearOptions.find(
                            (option) => Number(option.value) === Number(value)
                          ) || null
                        }
                        options={yearOptions}
                      />
                    )}
                  />
                )}

                {errors?.year_id && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.year_id.message}
                  </span>
                )}
              </div> */}
              <div className="mb-4">
                <label>Date</label>
                <input
                  {...register("date", {
                    required: "This field is required",
                  })}
                  type="date"
                />
                {errors?.date && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.date.message}
                  </span>
                )}
              </div>

              <div className="mb-4">
                <label>Holiday Type</label>
                <Controller
                  control={control}
                  name="holiday_type"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      onChange={(selectedOption) => {
                        onChange(selectedOption ? selectedOption.value : null);
                      }}
                      value={
                        holidayTypeOptions.find(
                          (option) => option.value === value
                        ) || null
                      }
                      options={holidayTypeOptions}
                      getOptionLabel={(option) => option.label}
                      getOptionValue={(option) => option.value}
                    />
                  )}
                />

                {errors?.holiday_type && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.holiday_type.message}
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label>Country</label>
                <Controller
                  control={control}
                  name="country_code"
                  render={({ field: { onChange, value } }) => (
                    <Select
                      components={{ DropdownIndicator }}
                      className="custom-select-container"
                      onChange={(selectedOption) => {
                        onChange(selectedOption?.value || null); // Store plain value
                        setSelectedCountry(selectedOption?.value || null); // For dependent state fetching
                      }}
                      value={
                        countryOptions.find(
                          (option) => option.value === value
                        ) || null
                      }
                      options={countryOptions}
                    />
                  )}
                />

                {errors?.country_code && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.country_code.message}
                  </span>
                )}
              </div>
              <div className="mb-4">
                <label>State</label>

                {isLoadingStates ? (
                  <span>Loading states...</span>
                ) : (
                  <Controller
                    control={control}
                    name="state_code"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        components={{ DropdownIndicator }}
                        className="custom-select-container"
                        onChange={(selectedOption) =>
                          onChange(selectedOption?.value || null)
                        }
                        value={
                          stateOptions.find(
                            (option) => option.value === value
                          ) || null
                        }
                        options={stateOptions}
                        isDisabled={!selectedCountry}
                      />
                    )}
                  />
                )}
                {errors?.state_code && (
                  <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                    {errors.state_code.message}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer text-right mt-4 pr-6">
          <button type="submit" className="btn btn--blue font-medium">
            {existingHolidayData.holiday_id ? "Edit" : "Add"} Holiday
          </button>
        </div>
      </form>
    </ReactSlidingPane>
  );
};

export default AddorEditHoliday;
