import React, { useState } from "react";
import { viewIcon } from "../../../Icons/Icons";
import { getFormattedFullDate, formatTime, calculateTimeDifference } from "../../../utils/FormatDate/formaDateInMonths";

const LeavePolicyCornTable = ({ employeeList, onViewDetails, onRetry }) => {
  const [modalData, setModalData] = useState(null);

  const headerCol = [
    { colName: "Employee Name", key: "employee_name" },
    { colName: "Date", key: "created_date" },
    { colName: "Time Taken", key: "time_taken" }, // New column for Time Taken
    { colName: "Renewal Status", key: "status_type" },
    { colName: "Action Type", key: "updated_type" },
    { colName: "Additional Details", key: "action_type" },
  ];

  const getStatusClass = (status) => {
    switch (status) {
      case "In-Process":
        return "status status--yellow";
      case "Success":
        return "status status--green";
      case "Failed":
        return "status status--red";
      case "Cancelled":
        return "status status--yellow";
      default:
        return "status status--default";
    }
  };

  const handleViewClick = (details) => {
    let parsedActionType = {};
    try {
      parsedActionType = JSON.parse(details.action_type);
    } catch (error) {
      console.error("Error parsing action_type:", error);
    }

    const modalDetails = {
      policyName: details.policy_name,
      startTime: formatTime(details.start_time), 
      endTime: formatTime(details.end_time),
      additionalDetails: {
        step: details.action_type.step || "N/A",
        status: details.action_type.status || "N/A",
        message: details.action_type.message || "No message provided",
      },
      year: details.year || "N/A",
    };

    setModalData(modalDetails);
  };

  const closeModal = () => {
    setModalData(null);
  };

  return (
    <div className="relative overflow-x-auto">
      <table className="min-w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            {headerCol.map((col, index) => (
              <th
                scope="col"
                className={`px-4 py-3 ${
                  col.key === "employee_name" ? "text-left" : "text-center"
                }`}
                key={index}
              >
                {col.colName}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {employeeList.map((val, index) => (
            <tr key={index} className="bg-white hover:bg-gray-50" style={{ border: "none" }}>
              {headerCol.map((header, headerIndex) => (
                <td
                  className={`px-4 py-3 ${
                    header.key === "employee_name" ? "text-left" : "text-center"
                  }`}
                  key={headerIndex}
                >
                  {header.key === "created_date" ? (
                    getFormattedFullDate(val[header.key])
                  ) : header.key === "time_taken" ? (
                    calculateTimeDifference(val.start_time, val.end_time) // Time Taken logic
                  ) : header.key === "status_type" ? (
                    <span className={getStatusClass(val[header.key])}>
                      {val[header.key] || "-"}
                    </span>
                  ) : header.key === "action_type" ? (
                    <div className="flex items-center justify-center">
                      <button
                        className="text-blue-600 flex items-center space-x-2 hover:underline"
                        onClick={() => handleViewClick(val)}
                      >
                        {viewIcon}
                      </button>
                    </div>
                  ) : (
                    val[header.key] || "-"
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {modalData && (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-1/3 shadow-lg">
            <h3 className="text-lg font-medium text-gray-700 mb-4">Additional Details</h3>
            <div>
              <p>
                <strong>Policy Name:</strong> {modalData.policyName || "No policy details available"}
              </p>
              <p>
                <strong>Renewed Year:</strong> {modalData.year}
              </p>
              <p>
                <strong>Start Time:</strong> {modalData.startTime}
              </p>
              <p>
                <strong>End Time:</strong> {modalData.endTime}
              </p>
              <div className="mb-4">
                <strong>Additional Details:</strong>
                <div className="mb-2">
                  <p>
                    <strong>Action Step:</strong> {modalData.additionalDetails.step}
                  </p>
                  <p>
                    <strong>Status:</strong> {modalData.additionalDetails.status}
                  </p>
                  <p>
                    <strong>Details:</strong> {modalData.additionalDetails.message}
                  </p>
                </div>
              </div>
            </div>

            <button
              className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeavePolicyCornTable;
