import React, { useState, useRef } from "react";
import HolidayListTable from "../HolidayListTable/HolidayListTable";
import Search from "../../../Header/Search/Search";
import Select from "react-select";
import Pagination from "../../../Pagination/Pagination";
import { DropdownIndicator } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import AddorEditHoliday from "../AddOrEditHoliday/AddorEditHoliday";
import { holidayTypeOptions } from "../../../../utils/commonSelectBoxOptions/commonSelectBoxOptions";
import EmptyState from "../../../EmptyState";
import { DatePicker } from "antd";
import { useHolidayDataList } from "../../../../queries/holidayQuries";
import {
  useGetCountryList,
  useGetStateList,
} from "../../../../queries/employeeQueries";

import PageLoader from "../../../PageLoader/PageLoader";
  
const HolidayList = ({ policyMapping, setSelectedPolicy }) => {
  const [isAddNewHoliday, setIsAddNewHoliday] = useState(false);
  const [location, setLocation] = useState({
    country: null,
    state: null,
    year: null,
    search: "",
  });
  const [page, setPage] = useState({ pageNo: 1, pageSize: 10 });
  const [holidayType, setHolidayType] = useState(null);

  const filterRef = useRef({});

  const isFilterApplied =
    location.country ||
    location.state ||
    location.year ||
    location.search ||
    holidayType;

  const params = {
    country_id: location.country,
    state_id: location.state,
    year: location.year,
    search: location.search,
    holiday_type_id: holidayType,
    page_number: page.pageNo,
    page_limit: page.pageSize,
  };

  const { data: holidayListData, isLoading, refetch } = useHolidayDataList(params);
  const { data: countryList } = useGetCountryList();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { data: stateList } = useGetStateList(selectedCountry);



  const countryOptions = countryList?.map((country) => ({
    label: country.flag,
    value: country.id,
  }));

  const stateOptions = stateList?.map((state) => ({
    label: state.state_name,
    value: state.id,
  }));

  const handleClearFilters = () => {
    setLocation({ country: null, state: null, year: null, search: "" });
    setHolidayType(null);
    setPage({ pageNo: 1, pageSize: 10 });

    Object.keys(filterRef.current).forEach((key) => {
      filterRef.current[key]?.clearValue();
    });
  };

  const handleHolidayTypeChange = (selectedOption) => {
    setHolidayType(selectedOption?.value || null);
  };

  const onYearChange = (date, dateString) => {
    setLocation((prev) => ({ ...prev, year: dateString }));
  };
  

  const handleSearchChange = (searchValue) => {
    setLocation((prev) => ({ ...prev, search: searchValue }));
  };

  return (
    <div className="holiday-list">
      {isAddNewHoliday && (
        <AddorEditHoliday
          open={isAddNewHoliday}
          close={() => setIsAddNewHoliday(false)}
          handleMakeApiCallToFetchHolidayList={refetch}
        />
      )}

      <div className="flex flex-wrap justify-between items-center px-10 py-6">
        <div>
          <h2>Holiday Lists</h2>
          <p>View and manage all your organization's holidays</p>
        </div>
        <button className="btn" onClick={() => setIsAddNewHoliday(true)}>
          <i className="ico-plus text-white mr-1"></i> New Holiday
        </button>
      </div>

      <div className="filters flex justify-between items-center px-10 mb-4 gap-3">
        <div className="flex gap-3">
          <DatePicker
            picker="year"
            onChange={onYearChange}
            placeholder="Select year"
          />
          <Select
            ref={(ele) => (filterRef.current.country = ele)}
            components={{ DropdownIndicator }}
            placeholder="Country"
            options={countryOptions}
            onChange={(e) => {
              setSelectedCountry(e?.value || null);
              setLocation((prev) => ({ ...prev, country: e?.value }));
            }}
          />

          <Select
            ref={(ele) => (filterRef.current.state = ele)}
            components={{ DropdownIndicator }}
            placeholder="State"
            options={stateOptions}
            onChange={(e) =>
              setLocation((prev) => ({ ...prev, state: e?.value }))
            }
          />
          <Select
            ref={(ele) => (filterRef.current.holiday_type = ele)}
            placeholder="Holiday Type"
            components={{ DropdownIndicator }}
            options={holidayTypeOptions}
            onChange={handleHolidayTypeChange}
            value={
              holidayTypeOptions.find(
                (option) => option.value === holidayType
              ) || null
            }
          />
          {isFilterApplied && (
            <button className="text-blue-600" onClick={handleClearFilters}>
              Clear Filters
            </button>
          )}
        </div>
        <div className="flex gap-3">
          <Search mx="0" onChange={handleSearchChange} />
          {/* <a href="#" className="btn btn--border">
            <i className="ico-download mr-1"></i> Download
          </a> */}
        </div>
      </div>

      {isLoading ? (
        <PageLoader />
      ) : holidayListData?.holidays?.length ? (
        <>
          <HolidayListTable
            holidayList={holidayListData?.holidays}
            policyMapping={policyMapping}
            setSelectedPolicy={setSelectedPolicy}
            handleMakeApiCallToFetchHolidayList={refetch}
          />
          <div className="mt-[65px]">
            <Pagination
              page={{
                pageNo: page.pageNo,
                setPageNo: (newPageNo) =>
                  setPage((prev) => ({ ...prev, pageNo: newPageNo })),
                pageSize: page.pageSize,
                setPageSize: (newPageSize) =>
                  setPage((prev) => ({ ...prev, pageSize: newPageSize })),
                totalRecords: holidayListData?.total || 0,
              }}
            />
          </div>
        </>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default HolidayList;
