import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { formatSearchParams } from "../utils/helperFunctions";
import queryKeys from "./queryKeys";

const {
  hrAdditionalLeaveApproval,
  leaveHrAdditionalApprovalList,
  leaveHrApprovalList,
  leaveTypeList,
  hrLeaveApproval,
  leavePolicyList,
  leaveManagerApprovalList,
  additionalLeaveManagerApprovalList,
  leaveDetails,
  additionalLeaveDetails,
  managerLeaveApproval,
  managerAdditionalLeaveApproval,
  managerLeaveDecline,
  managerAdditionalLeaveReject,
  hrLeaveDecline,
  hrAdditionalLeaveReject,
  leaveBalances,
  leaveRequestList,
  listConditionalLeaveType,
  additionalLeaveListForEmployee,
  applyLeave,
  applyAdditionalLeaves, cancelLeaveRequest,
  employeeLeaveBalanceList, listLeavePolicyMapping,
  manageNewEmployee, adjustLeaveBalance,getEmployeeLeaveBalaceById,
  getYearId,  policyRenewalLogData, 
  leaveModeListData, updateLeaveType, createLeaveType, leaveModeDetails,
  renewEmployeePolicy,
} = queryKeys;

export const useLeaveTypeList = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [leaveTypeList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/leaveTypesList", {
        params: formattedParams,
      });
      return data;
    },
  });
};

export const useLeavePolicyList = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [leavePolicyList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/listLeavePolicy", {
        params: formattedParams,
      });
      return data;
    },
  });
};

export const useHrLeaveApprovalList = (
  params = {},
  isLeaveReqTab,
  isHrRole
) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [leaveHrApprovalList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/hrApprovalList", {
        params: formattedParams,
      });
      return data;
    },
    enabled: isLeaveReqTab && isHrRole,
  });
};

export const useManagerLeaveApprovalList = (
  params = {},
  isLeaveReqTab,
  isManagerRole
) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [leaveManagerApprovalList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/managerApprovalList", {
        params: formattedParams,
      });
      return data;
    },
    enabled: isLeaveReqTab && isManagerRole,
  });
};

export const useManagerAdditionalLeaveApprovalList = (
  params = {},
  isAdditionalLeaveReqTab,
  isManagerRole
) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [additionalLeaveManagerApprovalList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "lms/leave/additionalLeaveManagerApprovalList",
        {
          params: formattedParams,
        }
      );
      return data;
    },
    enabled: isAdditionalLeaveReqTab && isManagerRole,
  });
};

export const useHrAdditionalLeaveApprovalList = (
  params = {},
  isAdditionalLeaveReqTab,
  isHrRole
) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [leaveHrAdditionalApprovalList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "lms/leave/additionalLeaveHrApprovalList",
        {
          params: formattedParams,
        }
      );
      return data;
    },
    enabled: isAdditionalLeaveReqTab && isHrRole,
  });
};

export const useGetLeaveDetails = (params = {}, isLeaveReqTab) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [leaveDetails, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/leaveDetails", {
        params: formattedParams,
      });
      return data;
    },
    enabled: !!params?.leave_application_id && isLeaveReqTab,
  });
};

export const useGetAdditionalLeaveDetails = (
  params = {},
  isAdditionalLeaveReqTab
) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [additionalLeaveDetails, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "lms/leave/additionalLeaveDetails",
        {
          params: formattedParams,
        }
      );
      return data;
    },
    enabled: !!params?.leave_application_id && isAdditionalLeaveReqTab,
  });
};

export const useHrLeaveApproval = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [hrLeaveApproval],
    mutationFn: async (payload) => {
      if (Array.isArray(payload)) {
        const response = await Promise.all(
          payload.map((i) => axiosPrivate.put("lms/leave/hrApproval", i))
        );
        return response.map((i) => i.data);
      } else {
        const { data } = await axiosPrivate.put(
          "lms/leave/hrApproval",
          payload
        );
        return data;
      }
    },
    onSuccess: (data) => {
      if (Array.isArray(data)) {
        if (data.length === 1) {
          const res = data[0];
          if (res.status) {
            queryClient.invalidateQueries([leaveHrApprovalList]);
          }
        } else {
          queryClient.invalidateQueries([leaveHrApprovalList]);
        }
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useHrAdditionalLeaveApproval = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [hrAdditionalLeaveApproval],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put(
        "lms/leave/hrAdditionalLeaveApproval",
        payload
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([leaveHrAdditionalApprovalList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useManagerLeaveApproval = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [managerLeaveApproval],
    mutationFn: async (payload) => {
      if (Array.isArray(payload)) {
        const response = await Promise.all(
          payload.map((i) => axiosPrivate.put("lms/leave/managerApproval", i))
        );
        return response.map((i) => i.data);
      } else {
        const { data } = await axiosPrivate.put(
          "lms/leave/hrApproval",
          payload
        );
        return data;
      }
    },
    onSuccess: (data) => {
      if (Array.isArray(data)) {
        if (data?.length === 1) {
          const res = data[0];
          if (res?.status) {
            queryClient.invalidateQueries([leaveManagerApprovalList]);
          }
        } else {
          queryClient.invalidateQueries([leaveManagerApprovalList]);
        }
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useManagerAdditionalLeaveApproval = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [managerAdditionalLeaveApproval],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put(
        "lms/leave/managerAdditionalLeaveApproval",
        payload
      );
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries([additionalLeaveManagerApprovalList]);
    },
    onError: () => {
      // handle error
    },
  });
};

export const useHrLeaveDecline = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [hrLeaveDecline],
    mutationFn: async (payload) => {
      if (Array.isArray(payload)) {
        const response = await Promise.all(
          payload.map((i) => axiosPrivate.put("lms/leave/hrReject", i))
        );
        return response.map((i) => i.data);
      } else {
        const { data } = await axiosPrivate.put("lms/leave/hrReject", payload);
        return data;
      }
    },
    onSuccess: (data) => {
      if (Array.isArray(data)) {
        if (data.length === 1) {
          const res = data[0];
          if (res.status) {
            queryClient.invalidateQueries([leaveHrApprovalList]);
          }
        } else {
          queryClient.invalidateQueries([leaveHrApprovalList]);
        }
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useHrAdditionalLeaveDecline = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [hrAdditionalLeaveReject],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put(
        "lms/leave/hrAdditionalLeaveReject",
        payload
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([leaveHrAdditionalApprovalList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useManagerLeaveDecline = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [managerLeaveDecline],
    mutationFn: async (payload) => {
      if (Array.isArray(payload)) {
        const response = await Promise.all(
          payload.map((i) => axiosPrivate.put("lms/leave/managerRejection", i))
        );
        return response.map((i) => i.data);
      } else {
        const { data } = await axiosPrivate.put(
          "lms/leave/managerRejection",
          payload
        );
        return data;
      }
    },
    onSuccess: (data) => {
      if (Array.isArray(data)) {
        if (data.length === 1) {
          const res = data[0];
          if (res.status) {
            queryClient.invalidateQueries([leaveManagerApprovalList]);
          }
        } else {
          queryClient.invalidateQueries([leaveManagerApprovalList]);
        }
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useManagerAdditionalLeaveDecline = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [managerAdditionalLeaveReject],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put(
        "lms/leave/managerAdditionalLeaveReject",
        payload
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([additionalLeaveManagerApprovalList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useGetLeaveBalances = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [leaveBalances, params, params.year],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/leaveBalances", {
        params: formattedParams,
      });
      return data;
    },
  });
};
export const useGetLeaveRequestList = (params = {}, isLeaveTabView) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [leaveRequestList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/leaveRequestList", {
        params: formattedParams,
      });
      return data;
    },
    enabled: isLeaveTabView,
  });
};
export const useGetListConditionalLeaveType = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [listConditionalLeaveType, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "lms/leave/listConditionalLeaveType",
        {
          params: formattedParams,
        }
      );
      return data;
    },
  });
};
export const useGetAdditionalLeaveListForEmployee = (params = {}, isAdditionalLeaveTab) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [additionalLeaveListForEmployee, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get(
        "lms/leave/additionalLeaveListForEmployee",
        {
          params: formattedParams,
        }
      );
      return data;
    },
    enabled: isAdditionalLeaveTab
  });
};

export const useApplyLeave = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [applyLeave],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "lms/leave/applyLeave",
        payload, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([leaveRequestList]);
        queryClient.invalidateQueries([leaveBalances]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useApplyAdditionalLeave = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [applyAdditionalLeaves],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post(
        "lms/leave/applyAdditionalLeaves",
        payload,
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([additionalLeaveListForEmployee]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};
export const useCancelLeave = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  return useMutation({
    mutationKey: [cancelLeaveRequest],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put(
        "lms/leave/cancelLeaveRequest",
        payload,
      );
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([leaveRequestList]);
      }
    },
    onError: () => {
      // handle error
    },
  });
};

export const useEmployeeLeaveBalanceList = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [employeeLeaveBalanceList, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/employeeLeaveBalanceList", {
        params: formattedParams,
      });
      return data;
    },
  });
}

export const useListLeavePolicyMapping = (params = {}, enabled) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [listLeavePolicyMapping, params],
    queryFn: async () => {

      const { data } = await axiosPrivate.get("lms/leave/listLeavePolicyMapping", {
        params: formattedParams,
      });
      return data;
    },
    enabled
  }); 
}

export const useAssignLeavePolicy = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();

  return useMutation({
    mutationKey: [manageNewEmployee],
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post('lms/leave/manageNewEmployee', payload);
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([leaveTypeList]);
      }
    },
    onError: (error) => {
    
    },
  });
};

export const useAdjustLeaveBalance = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();

  return useMutation({
    mutationKey: [adjustLeaveBalance], 
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put('lms/leave/adjustLeaveBalance', payload);
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([employeeLeaveBalanceList]);
      }
    },
    onError: (error) => {
      
    },
  });
};

export const useGetEmployeeLeaveBalanceById = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getEmployeeLeaveBalaceById, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/employeeLeaveBalanceList", {
        params: formattedParams,
      });
      return data;
    },
    enabled:!!params?.employee_serial
  });
}

export const   useGetYearListData = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [getYearId, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/getYearListData", {
        params: formattedParams,
      });
      return data;
    },
  });
}

export const useGetpolicyRenewalLogData = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [policyRenewalLogData, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/policyRenewalLogData", {
        params: formattedParams,
      });
      return data;
    },
  });
}

export const useGetLeaveAutoIcremetLogData = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [policyRenewalLogData, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/leaveAutoIcremetLogData", {
        params: formattedParams,
      });
      return data;
    },
  });
}

export const useGetLeaveModeTypeList = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
  return useQuery({
    queryKey: [leaveModeListData, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/leaveModeTypeList", {
        params: formattedParams,
      });
      return data;
    },
  });
}


export const useUpdateLeaveTypes = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();

  return useMutation({
    mutationKey: [updateLeaveType], 
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.put('lms/leave/updateLeaveType', payload);
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([leaveTypeList]);
      }
    },
    onError: (error) => {
      
    },
  });
};

export const useCreateLeaveTypes = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();

  return useMutation({
    mutationKey: [createLeaveType], 
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post('lms/leave/createLeaveType', payload);
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([leaveTypeList]);
      }
    },
    onError: (error) => {
      
    },
  });
};

export const useGetLeaveModeDetails = (params = {}) => {
  const formattedParams = formatSearchParams(params);
  const axiosPrivate = useAxiosPrivate();
   return useQuery({
    queryKey: [leaveModeDetails, params],
    queryFn: async () => {
      const { data } = await axiosPrivate.get("lms/leave/geLeaveModeDetailsList", {
        params: formattedParams,
      });
      return data;
    },
    enabled : !!params?.leave_type_id, 
  });
};


export const useRenewEmployeeLeavePolicy = () => {
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();

  return useMutation({
    mutationKey: [renewEmployeePolicy], 
    mutationFn: async (payload) => {
      const { data } = await axiosPrivate.post('lms/leave/employeePolicyRenewal', payload);
      return data;
    },
    onSuccess: (data) => {
      if (data?.status) {
        queryClient.invalidateQueries([policyRenewalLogData]);
      }
    },
    onError: (error) => {
      
    },
  });
};