import React from "react";
import { infoIcon } from "../../Icons/Icons";
import { Tooltip } from "antd";

const LeavePercentage = ({ leavedata }) => {
  const leaveName = leavedata.leave_name;
  const leaveused = leavedata.number_days_allowed - leavedata.leave_taken;
  const leaveBalPercentage = (leaveused / leavedata.number_days_allowed) * 100;
  const percentage = leaveBalPercentage > 100 ? 100 : leaveBalPercentage;
  const isLopLeaveType = leavedata.is_lop;

  let color =
    percentage > 75
      ? "bg-[#9AB3F3]"
      : percentage > 50
      ? "bg-[#C4B5FD]"
      : percentage > 25
      ? "bg-[#FDBA74]"
      : percentage > 0
      ? "bg-[#FCA5A5]"
      : "";

  const leaveBalanceHours = leavedata?.leave_balance_hours || 0;
  const leaveBalanceDays = leavedata?.leave_balance_days || 0;
  // use tailwindclass insted ofobj
  // reduces days and hours

  const availableBalance = (
    <div className="text-center text-lg">
      <span>
        <strong className="text-1xl">{leaveBalanceDays}</strong>{" "}
        <span className="text-sm">days</span>
      </span>
      {leaveBalanceHours ? (
        <>
          <br />
          <span>
            <strong className="text-1xl">{leaveBalanceHours}</strong>{" "}
            <span className="text-sm">hours</span>
          </span>
        </>
      ) : null}
    </div>
  );

  const numberOfDaysAllowed = leavedata.number_days_allowed;
  const maxDaysCanBeAccumulated = leavedata.max_limit_can_be_accumulated || 0;
  const bookedDays = leavedata?.booked_day || 0;
  const bookedHours = leavedata?.booked_hours || 0;
  const booked = `${bookedDays} days ${
    bookedHours ? `${bookedHours} hours` : ""
  }`;
  let description = leavedata?.description || "";
  // number bold
  // availble => only days and hours
  // no proceressbar
  description += `<br/>
     <div style='display: flex; gap: 0.5rem; flex-direction: column; border: 1px solid #a8aaad; padding: 0.5rem; border-radius: 0.5rem; margin-top: 0.1rem;'>
     <div style='display: flex; justify-content: space-between; align-items: center;'>
     <span>Maximum days
      can be accumulated: </span>
     <span style='font-weight: 600;'>${maxDaysCanBeAccumulated}</span>
     </div>
     <div style='display: flex; justify-content: space-between; align-items: center;'>
     <span>Total leave allocated: </span>
     <span style='font-weight: 600;'>${numberOfDaysAllowed}</span>
     </div>
     </div>`;
  return (
    <div className="">
      <div className="flex relative justify-between items-center mb-4">
        <h3 className="font-semibold text-gray-800">{leaveName}</h3>

        <Tooltip
          title={
            <div
              className=""
              dangerouslySetInnerHTML={{ __html: description }}
            ></div>
          }
          color="geekblue"
          trigger={"click"}
        >
          <span className="cursor-pointer">{infoIcon}</span>
        </Tooltip>
      </div>
      {!isLopLeaveType ? (
        <div className="text-sm justify-between flex gap-2 text-gray-500">
          <span>Available Balance: </span>
          <span className="text-gray-800 font-medium">{availableBalance}</span>
        </div>
      ) : null}
      {/* <div className="h-2 rounded-xl bg-gray-100 mb-2">
        <div
          className={`h-2 rounded-xl ${color}`}
          style={{ width: percentage + "%" }}
        ></div>
      </div> */}
      <div className="mt-2 text-sm justify-between flex gap-2 text-gray-500">
        <span>Booked:</span>
        <span className="text-gray-900">{booked}</span>
      </div>
    </div>
  );
};

export default LeavePercentage;
