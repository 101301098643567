import React from "react";
import HolidayListData from "../HolidayListData/HolidayListData";

const HolidayListTable = ({
  holidayList,
  policyMapping,
  setSelectedPolicy,
  handleMakeApiCallToFetchHolidayList,
}) => {

  return (
    <div className=" w-full overflow-x-auto mb-20 ">
      <table className=" text-sm text-left text-gray-500 dark:text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            {setSelectedPolicy && (
              <th scope="col" className="px-10 py-3">
                <input type="checkbox" />
              </th>
            )}
            <th scope="col" className="px-10 py-3">
              Holiday Name
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th scope="col" className="px-10 py-3">
              Date
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th scope="col" className="px-10 py-3">
              Holiday Type
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th scope="col" className="px-10 py-3">
              Country
              <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
            </th>
            <th
              scope="col"
              className="px-10 py-3 text-right cursor-pointer"
            ></th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(holidayList) &&
            holidayList?.map((val, key) => {
              return (
                <HolidayListData
                key={`holiday_list_data${key}`}
                  data={val}
                  index={key}
                  policyMapping={policyMapping}
                  setSelectedPolicy={setSelectedPolicy}
                  handleMakeApiCallToFetchHolidayList={handleMakeApiCallToFetchHolidayList}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default HolidayListTable;
