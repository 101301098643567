import React from "react";
import LeaveTypeListData from "./LeaveTypeListData";

const LeaveTypeListTable = ({ leaveTypes }) => {
  return (
    <div class="relative px-10 pb-10">
      {leaveTypes &&
        leaveTypes.map((val, key) => {
          return (
            <LeaveTypeListData
              requestdata={val}
              key={key}
            />
          );
        })}
    </div>
  );
};

export default LeaveTypeListTable;
