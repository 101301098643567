import React, { useState, useContext } from "react";
import "react-responsive-modal/styles.css";
import Line from "../../components/Line/Line";
import Avatar from "../../components/Avatar/Avatar";
import formatLeaveApplicationStatus from "../../utils/FormatLeaveApplicationStatus/formatLeaveApplicationStatus";
import formatDate from "../../utils/FormatDate/formatDate";
import formatLeaveDuration from "../../utils/FormatLeaveDuration/formatLeaveDuration";
import ViewEmployeeLeaveWithDateRange from "./ViewEmployeeLeaveWithDateRange";
import ApproveLeaveModal from "./LeaveApplicationActionModal";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import { useSelector } from "react-redux";
import ReactSlidingPane from "react-sliding-pane";
import { closeIcon } from "../../Icons/Icons";
import formatAvatarLetter from "../../utils/FormatAvatarLetter/formatAvatarLetters";
import useFetchFile from "../../hooks/useFetchFile";
import { LEAVE_FILES_URL } from "../../Consts/filePathConst";
import UpdateApprovalRemark from "../../components/LeaveDetailsModal/UpdateApprovalRemark";
import userRoles from "../../config/userRoles";
import { LeaveApprovalContext } from "./TaskLeaveApplications";
import {
  useGetAdditionalLeaveDetails,
  useGetLeaveDetails,
} from "../../queries/leaveQueries";
import HashLoader from "react-spinners/HashLoader";
import EmptyState from "../../components/EmptyState/EmptyState";
import { LEAVE_MODAL_ACTIONS } from "../../Consts/consts";

const PENDING_STATUS_LABEL = "Pending";

const LeaveDetailsModal = ({
  openModal,
  onCloseModal,
  leaveApplicationId,
}) => {
  const { isLeaveReqTab, isAdditionalLeaveReqTab } =
    useContext(LeaveApprovalContext);

  const {
    data: getAdditionalLeaveDetails,
    isLoading: isGetAdditionalLeaveDetailsLoading,
  } = useGetAdditionalLeaveDetails(
    {
      leave_application_id: leaveApplicationId,
      include_other_employees: true,
    },
    isAdditionalLeaveReqTab
  );

  const { data: getLeaveDetails, isLoading: isGetLeaveDetailsLoading } =
    useGetLeaveDetails(
      {
        leave_application_id: leaveApplicationId,
        include_other_employees: true,
      },
      isLeaveReqTab
    );

  const isLoading =
    isGetAdditionalLeaveDetailsLoading || isGetLeaveDetailsLoading;



  const fullDetails = isLeaveReqTab
    ? getLeaveDetails?.data || null
    : isAdditionalLeaveReqTab
    ? getAdditionalLeaveDetails?.data || null
    : null;

  const fetchFile = useFetchFile();
  const [viewEmployeeLeaveModal, setViewEmployeeLeaveModal] = useState(false);
  const [isUpdateRemarkModal, setUpdateRemarkModal] = useState(false);
  const user = useSelector((state) => state.user.auth);
  const authState = useSelector((state) => state.user);
  const { settingValue: setting } = authState;
  const isHrLeaveApprovalRequired = setting?.isHrLeaveApprovalRequired;
  const isHrAdditionalLeaveApprovalRequired =
    setting?.isHrLeaveApprovalRequiredForAdditionalLeaves;

  const isHr = userRoleAuth(user, userRoles.HR);
  const isManager = userRoleAuth(user, userRoles.MANAGER);

  const leave = fullDetails?.leave_details;

  const hr = fullDetails?.hr_details;
  const manager = fullDetails?.manager_details;

  const leaveApplicationApprovalPendingStatusId = 2;

  const requestedBy = `${leave?.first_name} ${leave?.last_name}`;
  const avatarLetter = formatAvatarLetter(requestedBy);
  const department = leave?.department_name;
  const designation = leave?.designation_title;
  const leaveSerial = leave?.leave_serial;
  const applicationDate = formatDate(leave?.application_date);
  const leaveType = leave?.leave_name;
  const fromDate = formatDate(
    isLeaveReqTab ? leave?.from_date : leave?.applied_for_date
  );
  const appliedForDate = formatDate(leave?.applied_for_date)?.dateString2;
  const toDate = formatDate(leave?.to_date) || "";
  const durationInDay = leave?.duration_in_day || 0;
  const durationInHour = leave?.duration_in_hour || 0;
  const duration = isLeaveReqTab
    ? formatLeaveDuration({ durationInDay, durationInHour })
    : "1 Day";

  const reasonForLeave = leave?.leave_reason || "No remarks";
  const leaveDocument = leave?.leave_document;

  const isCanceled = leave?.is_canceled;
  const status = isCanceled
    ? formatLeaveApplicationStatus("canceled")
    : formatLeaveApplicationStatus(
        hr?.hr_approval_status_label,
        manager?.manager_approval_status_label
      );
  const { managerStatusLabel, hrStatusLabel } = status;
  const managerStatus = formatLeaveApplicationStatus(managerStatusLabel);
  const hrStatus = formatLeaveApplicationStatus(hrStatusLabel);

  const isHrRemarkEditable =
    Number(hr?.hr_approval_status_id) !==
      leaveApplicationApprovalPendingStatusId &&
    !isCanceled &&
    isHr && isLeaveReqTab;
  const isManagerRemarkEditable =
    Number(manager?.manager_approval_status_id) !==
      leaveApplicationApprovalPendingStatusId &&
    !isCanceled &&
    isManager && isLeaveReqTab;

  const managerName = `${manager?.first_name} ${manager?.last_name}`;
  const managerPhoto = manager?.photo;
  const managerRemark =
    manager?.remark || leave?.manager_comments || "No remarks";
  const managerDesignation = manager?.designation_title;
  const managerRemarkUpdatedTime = formatDate(manager?.updated_time);
  const isManagerRemarkEdited = Number(manager?.total_remark_updated_count) > 1;

  const formatManagerRemarkUpdateTime =
    managerStatusLabel === PENDING_STATUS_LABEL
      ? "--:-- --"
      : `${managerRemarkUpdatedTime?.localDate1 || ""} - ${
          managerRemarkUpdatedTime?.localString2 || ""
        }`;

  const hrName = `${hr?.first_name} ${hr?.last_name}`;
  const hrPhoto = hr?.photo;
  const hrRemark = hr?.remark || leave?.hr_comments;
  const hrDesignation = hr?.designation_title;
  const hrRemarkUpdatedTime = formatDate(hr?.updated_time);
  const isHrRemarkEdited = Number(hr?.total_remark_updated_count) > 1;

  const formatHrRemarkUpdateTime =
    hrStatusLabel === PENDING_STATUS_LABEL
      ? "--:-- --"
      : `${hrRemarkUpdatedTime?.localDate1 || ""} - ${
          hrRemarkUpdatedTime?.localString2 || ""
        }`;

  const otherEmployeesTookLeave = fullDetails?.other_emp_details || [];

  let isEligibleForAction;

  if (isLeaveReqTab) {
    isEligibleForAction = isHr ? isHrLeaveApprovalRequired : true;
  } else {
    isEligibleForAction = isHr ? isHrAdditionalLeaveApprovalRequired : true;
  }
  const isEligibleToTakeActions =
    (isCanceled
      ? false
      : isHr && hrStatusLabel === PENDING_STATUS_LABEL
      ? true
      : isManager && managerStatusLabel === PENDING_STATUS_LABEL
      ? true
      : false) && isEligibleForAction;

  const showHrApprovalStatus = isAdditionalLeaveReqTab
    ? isHrAdditionalLeaveApprovalRequired
    : isHrLeaveApprovalRequired;

  const downloadLeaveDocument = async () => {
    if (leaveDocument) {
      const leaveFile = await fetchFile(`${LEAVE_FILES_URL}/${leaveDocument}`);
      const link = document.createElement("a");
      link.href = leaveFile;
      link.download = `${requestedBy}-leave_document.pdf`;
      link.target = "_blank";
      link.rel = "noopener noreferrer";
      link.click();
    }
  };

  const handleOpenUpdateRemarkModal = () => {
    if (isLeaveReqTab) {
      setUpdateRemarkModal(true);

    }
  };

  const [leaveActionModal, setLeaveActionModal] = useState({
    isOpen: false,
    action: "",
  });

  const handleSetModal = (modal, status) => {
    setLeaveActionModal((prev) => ({
      ...prev,
      isOpen: status,
      action: modal || "",
    }));
  };

  const onSuccessLeaveAction = () => {
    onCloseModal();
  };


  return (
    <>
      {isUpdateRemarkModal && (
        <UpdateApprovalRemark
          open={isUpdateRemarkModal}
          close={() => setUpdateRemarkModal(false)}
          leaveApplicationId={leave?.leave_application_id}
          leaveApprovalStatusId={
            isHr
              ? hr?.hr_approval_status_id
              : manager.manager_approval_status_id
          }
          remark={isHr ? hrRemark : managerRemark}
          onCloseModal={onCloseModal} // slidingpanel
        />
      )}
      {leaveActionModal.isOpen ? (
        <ApproveLeaveModal
          openModal={leaveActionModal.isOpen}
          onCloseModal={() => handleSetModal("", false)}
          leaveApplications={{
            ...leave,
            manager_approval_status_id: manager?.manager_approval_status_id,
            hr_approval_status_id: hr?.hr_approval_status_id,
            hr_approval_status_label: hr?.hr_approval_status_label,
            manager_approval_status_label: manager?.manager_approval_status_label
          }}
          action={leaveActionModal.action}
          onSuccess={onSuccessLeaveAction}
        />
      ) : null}
      
      <ViewEmployeeLeaveWithDateRange
        openModal={viewEmployeeLeaveModal}
        onCloseModal={() => setViewEmployeeLeaveModal(false)}
        data={otherEmployeesTookLeave}
      />
      <ReactSlidingPane
        isOpen={openModal}
        onRequestClose={onCloseModal}
        width="550px"
      >
        {isLoading ? (
          <div className="flex items-center justify-center h-full">
            <HashLoader
              loading={true}
              size={60}
              color={"#307af2"}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </div>
        ) : fullDetails?.leave_details ? (
          <div className="w-[540px]">
            <>
              <div className="flex items-center justify-between px-10 py-3">
                <h2 className="text-lg font-semibold">
                  {leave.additional_leave_application_id
                    ? "Additonal Leave Request Details"
                    : "Leave Request Details"}
                </h2>

                <div className="flex items-center gap-2">
                  {/* <button className="text-blue-600">View History</button> */}
                  <button onClick={onCloseModal}>{closeIcon}</button>
                </div>
              </div>
              <Line />

              <div className="px-10">
                <div className="flex gap-2 pb-3">
                  <Avatar title={avatarLetter} />
                  <div>
                    <p className="text-black font-medium">{requestedBy}</p>
                    <span>
                      {designation} | {department}
                    </span>
                  </div>
                </div>

                <div className="flex justify-between mb-1.5">
                  <ul className="text-sm flex flex-col gap-1.5">
                    <li>Request ID</li>
                    <li>Approval Status</li>
                    <li>Requested Date</li>
                    <li>Type</li>
                    {
                      isLeaveReqTab ? <li>Leave Date</li> : <li>Applied for Date</li>
                    }
                    <li>Total</li>
                  </ul>
                  <ul className="text-sm font-medium text-[#1F2937] flex flex-col gap-1.5 items-end">
                    <li>{leaveSerial}</li>
                    <li>
                      <span className={status.leaveStatusClass}>
                        {status.leaveStatus}
                      </span>
                    </li>
                    <li>
                      {applicationDate.dateString2}{" "}
                      {applicationDate.localString2}
                    </li>

                    <li>{leaveType}</li>
                   {
                    isLeaveReqTab ?  <li>
                    {fromDate.dateString2} - {toDate.dateString2}
                  </li> :  <li>
                      {appliedForDate}
                    </li>
                   }
                    <li>{duration}</li>
                  </ul>
                </div>

                <div className="mb-4">
                  <p className="text-sm mb-2">Employee Note</p>
                  <div className="p-4 bg-[#F9FAFB] flex gap-3 items-baseline">
                    <i className="ico-reply"></i>
                    <p className="text-sm">{reasonForLeave}</p>
                  </div>
                  <p className="text-sm mt-4">Attachment</p>
                  <div
                    className="flex gap-3 items-center cursor-pointer"
                    onClick={downloadLeaveDocument}
                  >
                    <i className="ico-doc"></i>
                    {leaveDocument ? (
                      <p className="text-sm my-3">leave_document.pdf</p>
                    ) : (
                      <p className="text-sm my-3">No attachment found</p>
                    )}
                  </div>
                </div>
                <Line />

                {Array.isArray(otherEmployeesTookLeave) &&
                otherEmployeesTookLeave.length ? (
                  <button
                    type="button"
                    className="btn btn--border text-sm"
                    onClick={() => setViewEmployeeLeaveModal(true)}
                  >
                    View employee(s) leave on the same date range
                  </button>
                ) : (
                  <p className=" text-blue-600 bg-blue-100 p-2 rounded-md">
                    No other employee applied for same date
                  </p>
                )}
                <div className="my-5">
                  <h3 className="font-semibold text-[#1F2937] mb-4">
                    Approver Status
                  </h3>
                  {showHrApprovalStatus && (
                    <>
                      <div className="flex justify-between items-center">
                        <div className="flex gap-3 items-center">
                          <Avatar image={hrPhoto} />
                          <div>
                            <h4 className="text-sm font-medium text-[#1F2937]">
                              {hrName}
                            </h4>
                            <p className="text-sm">{hrDesignation}</p>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <p className="text-sm">{formatHrRemarkUpdateTime}</p>
                          <span className={hrStatus.leaveStatusClass}>
                            {hrStatus.leaveStatus}
                          </span>
                        </div>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="p-4 w-full bg-[#F9FAFB] flex gap-3 items-baseline mt-3">
                          <i className="ico-reply"></i>
                          <p className="text-sm">
                            {hrRemark}{" "}
                            {isHrRemarkEdited && (
                              <span className="text-gray-400 text-xs">
                                (Edited)
                              </span>
                            )}{" "}
                          </p>
                        </div>
                        {isHrRemarkEditable && (
                          <span className="cursor-pointer">
                            <img
                              src="/images/editicon.svg"
                              alt=""
                              onClick={handleOpenUpdateRemarkModal}
                            />
                          </span>
                        )}
                      </div>
                    </>
                  )}

                  <div className="flex justify-between items-center">
                    <div className="flex gap-3 items-center">
                      <Avatar image={managerPhoto} />
                      <div>
                        <h4 className="text-sm font-medium text-[#1F2937]">
                          {managerName}
                        </h4>
                        <p className="text-sm">{managerDesignation}</p>
                      </div>
                    </div>
                    <div className="flex items-center gap-2">
                      <p className="text-sm">{formatManagerRemarkUpdateTime}</p>
                      <span className={managerStatus.leaveStatusClass}>
                        {managerStatus.leaveStatus}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="p-4 w-full bg-[#F9FAFB] flex gap-3 items-baseline mt-3">
                      <i className="ico-reply"></i>
                      <p className="text-sm">
                        {managerRemark}{" "}
                        {isManagerRemarkEdited && (
                          <span className="text-gray-400 text-xs">
                            (Edited)
                          </span>
                        )}
                      </p>
                    </div>
                    {isManagerRemarkEditable && (
                      <span className="cursor-pointer">
                        <img
                          src="/images/editicon.svg"
                          alt=""
                          onClick={handleOpenUpdateRemarkModal}
                        />
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {managerStatus.leaveStatus !== "Declined" ? (
                <>
                  {isEligibleToTakeActions ? (
                    <>
                      <div className="flex justify-end gap-3 mt-6 px-10">
                        <button
                          type="button"
                          className="btn btn--red text-sm font-medium"
                          onClick={() =>
                            handleSetModal(LEAVE_MODAL_ACTIONS.decline, true)
                          }
                        >
                          Decline
                        </button>
                        <button
                          type="button"
                          className="btn btn--green text-sm font-medium"
                          onClick={() =>
                            handleSetModal(LEAVE_MODAL_ACTIONS.approve, true)
                          }
                        >
                          Approve
                        </button>
                      </div>
                    </>
                  ) : null}
                </>
              ) : null}
            </>
          </div>
        ) : (
          <EmptyState />
        )}
      </ReactSlidingPane>
    </>
  );
};

export default LeaveDetailsModal;
