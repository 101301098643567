import React, { useRef, useState } from "react";
import Select, { components } from "react-select";
import Search from "../../Header/Search/Search";
import Pagination from "../../Pagination/Pagination";
import EmptyState from "../../EmptyState/EmptyState";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../../config/userRoles";
import { useSelector } from "react-redux";
import { useGetLocationList, useGetDepartmentList, useGetDesignationList } from "../../../queries/employeeQueries";
import { useGetLeaveAutoIcremetLogData } from "../../../queries/leaveQueries";
import PageLoader from "../../PageLoader/PageLoader";
import LeavePolicyCornTable from "./LeaveAutoIncrementCornTable";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <span className="ico-down text-blue-600"></span>
  </components.DropdownIndicator>
);

const DEPARTMENT = "department";
const DESIGNATION = "designation";
const OFFICE = "office";
const FILTER = "filter";
const PAGENO = "pageNo";
const PAGESIZE = "pageSize";
const STATUS = "status"; 

const LeaveAutoIncrementLogs = () => {
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, userRoles.HR);
  const searchRef = useRef();
  const [searchFilter, setFilter] = useState({
    pageNo: 1,
    pageSize: 10,
    filter: "",
    department: "",
    designation: "",
    office: "",
    status: "", 
  });
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null); 
  const isFiltered =
    searchFilter.filter ||
    searchFilter.department ||
    searchFilter.designation ||
    searchFilter.office ||
    searchFilter.status; 

  const { data: incrementLogData, isLoading } = useGetLeaveAutoIcremetLogData(searchFilter);
  const employeeList = incrementLogData?.data || [];
  const totalRecords = incrementLogData?.total || 0;

  const defaultLocationOption = { location_name: "Office: All", id: null };
  const defaultDesignationOption = {
    designation_title: "Designation: All",
    id: null,
  };
  const defaultDepartmentOption = {
    department_name: "Department: All",
    id: null,
  };

  const statusOptions = [
    { label: "Status: All", value: "" },
    { label: "In Progress", value: 1 },
    { label: "Success", value: 2 },
    { label: "Failed", value: 3 },
    { label: "Cancelled", value: 4 },
  ];

  const { data: getLocationList } = useGetLocationList();
  const { data: getDepartmentList } = useGetDepartmentList();
  const { data: getDesignationList } = useGetDesignationList();

  const departmentList = Array.isArray(getDepartmentList?.result)
    ? [defaultDepartmentOption, ...getDepartmentList?.result]
    : [];

  const designationList = Array.isArray(getDesignationList?.result)
    ? [defaultDesignationOption, ...getDesignationList?.result]
    : [];

  const locationList = Array.isArray(getLocationList?.result)
    ? [defaultLocationOption, ...getLocationList?.result]
    : [];

  const handleSetFilter = (value, source) => {
    setFilter((prev) => ({
      ...prev,
      [source]: value,
    }));
  };

  const handleClearFilters = () => {
    setFilter((prev) => ({
      ...prev,
      filter: "",
      department: "",
      designation: "",
      office: "",
      status: "", 
    }));

    setSelectedDesignation(null);
    setSelectedDepartment(null);
    setSelectedOffice(null);
    setSelectedStatus(null); 

    if (searchRef.current) {
      searchRef.current.value = "";
    }
  };

  return (
    <>
      <div className="w-full overflow-x-auto">
        <div className="px-10 py-6">
          <div className="flex justify-between items-center pb-4">
            <div>
              <h2>Leave Auto Increment Logs</h2>
              <p className="pb-4">
                {isHr ? "Customize and adjust all" : "View"} employee leave auto increment
                log
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex gap-3 items-center">
              <Select
                value={selectedDesignation}
                onChange={(selected) => {
                  setSelectedDesignation(selected);
                  handleSetFilter(selected?.value, DESIGNATION);
                }}
                placeholder={"Designation: All"}
                options={designationList.map((i) => ({
                  label: i.designation_title,
                  value: i.id,
                }))}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              <Select
                value={selectedDepartment}
                onChange={(selected) => {
                  setSelectedDepartment(selected);
                  handleSetFilter(selected?.value, DEPARTMENT);
                }}
                placeholder={"Department: All"}
                options={departmentList.map((i) => ({
                  label: i.department_name,
                  value: i.id,
                }))}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              <Select
                value={selectedOffice}
                onChange={(selected) => {
                  setSelectedOffice(selected);
                  handleSetFilter(selected?.value, OFFICE);
                }}
                placeholder={"Office: All"}
                options={locationList.map((i) => ({
                  label: i.location_name,
                  value: i.id,
                }))}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              {/* Status filter dropdown */}
              <Select
                value={selectedStatus}
                onChange={(selected) => {
                  setSelectedStatus(selected);
                  handleSetFilter(selected?.value, STATUS);
                }}
                placeholder={"Status: All"}
                options={statusOptions}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              {isFiltered && (
                <span
                  className="text-blue-800 font-medium cursor-pointer"
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </span>
              )}
            </div>
            <div>
              <Search
                eleRef={searchRef}
                onChange={(e) => handleSetFilter(e, FILTER)}
                value={searchFilter.filter}
              />
            </div>
            <div className="flex">
              {isHr && (
                <button className="btn btn--border text-[14px]">
                  <span>
                    <i className="ico-download mr-1"></i>
                  </span>
                  Download
                </button>
              )}
            </div>
          </div>
        </div>
        {isLoading ? (
          <PageLoader />
        ) : Array.isArray(employeeList) && employeeList.length ? (
          <>
            <LeavePolicyCornTable employeeList={employeeList} />
            <div className="mt-[65px]">
              <Pagination
                page={{
                  pageNo: searchFilter.pageNo,
                  setPageNo: (e) => handleSetFilter(e, PAGENO),
                  pageSize: searchFilter.pageSize,
                  setPageSize: (e) => handleSetFilter(e, PAGESIZE),
                  totalRecords: totalRecords,
                }}
              />
            </div>
          </>
        ) : (
          <EmptyState />
        )}
      </div>
    </>
  );
};

export default LeaveAutoIncrementLogs;
