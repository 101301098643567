import React, { useState } from "react";
import CustomizeTableColumn from "../../CustomizeTableColumn/CustomizeTableColumn";
import EmployeeModuleListData from "../EmployeeModuleListData"

const EmployeeModuleList = (props) => {
  const employeeList = props.data;
  const { handleSelectEmployee, selectedEmployees } = props;
  const [tableCol, setTableCol] = useState([
    { col: "ID", status: true, checkbox: true },
    { col: "Employee Name", status: true },
    { col: "Line Manager", status: true },
    { col: "HR", status: true },
    { col: "Designation", status: true },
    { col: "Department", status: true },
    { col: "Location", status: true },
    { col: "Portal Access", status: true },
    { col: "Employement Status", status: true },
    { col: "Actions", status: true },
  ]);
  const [isSidePanelOpen, setSidePanelState] = useState(false);

  const leavePolicy = props.leavePolicyList;

  return (
    <div className="relative overflow-x-auto min-h-[500px]">
      <CustomizeTableColumn
        tableCol={tableCol}
        setTableCol={setTableCol}
        isSidePanelOpen={isSidePanelOpen}
        setSidePanelState={setSidePanelState}
      />
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500 overflow-auto">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            {tableCol &&
              tableCol.map((item, index) => {
                if (item.status) {
                  return (
                    <th
                      scope="col"
                      className={`px-8 py-3 ${
                        item.col === "Actions" ? "sticky right-0 bg-gray-50 z-20" : ""
                      }`}
                      key={index}
                    >
                      <div className="flex items-center">
                        {item.checkbox ? (
                          <input
                            type="checkbox"
                            onClick={(e) => handleSelectEmployee(null, e.target.checked)}
                            className="mr-3"
                          />
                        ) : null}
                        {item.col}{" "}
                        {item.col !== "Actions" && (
                          <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
                        )}
                      </div>
                    </th>
                  );
                }
              })}
            <th
              scope="col"
              className="px-8 py-3 text-right cursor-pointer"
              onClick={() => {
                setSidePanelState(!isSidePanelOpen);
              }}
            >
              <i className="ico-grid text-blue-600"></i>
            </th>
          </tr>
        </thead>
        <tbody>
          {employeeList &&
            employeeList.map((val, key) => {
              return (
                <EmployeeModuleListData
                  requestdata={val}
                  key={key}
                  leaveTypes={props.leaveTypes}
                  leavePolicy={leavePolicy}
                  selectedEmployees={selectedEmployees}
                  handleSelectEmployee={handleSelectEmployee}
                />
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default EmployeeModuleList;
