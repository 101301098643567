import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import useFetchFile from "../../hooks/useFetchFile";
import { PROFILE_IMAGE_URL } from "../../Consts/filePathConst";

const Avatar = (props) => {
  const fetchFile = useFetchFile();
  const defaultAvatar = "/images/avatar.jpg";
  const isBlobImage = props?.image?.split(":")[0] === "blob" ? true : false;
  const [image, setImage] = useState(defaultAvatar);

  const fetchUserProfileImage = async () => {
    const imgSrc = await fetchFile(`${PROFILE_IMAGE_URL}/${props.image}`);
    setImage(imgSrc || defaultAvatar);
  };

  const onImageLoadError = () => {
    setImage("/images/avatar.jpg");
  };

  useEffect(() => {
    if (
      props.image === "'" ||
      props.image === null ||
      props.image === "null" ||
      props.image === undefined ||
      props.image === "undefined"
    ) {
      setImage(defaultAvatar);
      return;
    }
    if (isBlobImage) {
      setImage(props.image);
    } else if (props.image) {
      fetchUserProfileImage();
    } else {
      setImage(defaultAvatar);
    }
  }, [props?.image]);

  return (
    <div
      className={
        props.isProfileSideBar
          ? "user-profile-side-bar"
          : `avatar-div relative w-${props.width || "10"} h-${
              props.height || "10"
            } ${props.classes || ""}`
      }
    >
      <Link to={props.link || "#"} className="inline-block">
        {props.title ? (
          <>
            <div
              className={`relative inline-flex items-center justify-center w-${
                props.width || "10"
              } h-${
                props.height || "10"
              } overflow-hidden bg-gray-200 rounded-full dark:bg-gray-600`}
            >
              <span className="font-medium text-gray-600 dark:text-gray-300">
                {props.title}
              </span>
            </div>
          </>
        ) : (
          <figure
            className={`w-${props.width || "10"} h-${
              props.height || "10"
            } overflow-hidden rounded-full`}
          >
            <img
              id="avatar"
              src={image}
              className="w-full h-auto"
              alt="image"
              onError={onImageLoadError}
            />
          </figure>
        )}

        {props.status === "available" && (
          <span className="absolute block w-[14px] h-[14px] bg-green-400 rounded-full right-0 -bottom-[0px] border-[2px] border-white"></span>
        )}
      </Link>
    </div>
  );
};

export default Avatar;
