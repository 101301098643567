import useAxiosPrivate from "./useAxiosPrivate";

const useFetchFile = () => {
  const axiosPrivate = useAxiosPrivate();

  const fetchFile = async (filePathUrl = "") => {
    if (!filePathUrl) {
      console.error("File name and path is required to fetch");
      return false;
    }
    let url = `${process.env.REACT_APP_BASE_URL}${filePathUrl}`;
    try {
      const notAllowedFileNames = [
        "'",
        undefined,
        null,
        "undefined",
        "null",
        "",
        "images/avatar.jpg",
        "/images/avatar.jpg",
        "avatar.jpg"
      ];
      const fileName = filePathUrl?.split?.("/")?.pop();
      if (notAllowedFileNames.includes(fileName)) {
        return false;
      }
      if (url) {
        const response = await axiosPrivate
          .get(url, {
            responseType: "blob",
          })
          .then((res) => res)
          .catch((err) => err);
        const fileSrc = URL.createObjectURL(response.data);
        return fileSrc;
      }
    } catch (err) {
      return false;
    }
  };
  return fetchFile;
};

export default useFetchFile;
