import React, { Fragment, useState, useEffect } from "react";
import ApplyLeaveModal from "../../../components/ApplyLeaveModal/ApplyLeaveModal";
import LeaveItem from "./LeaveItem";
import { useSelector } from "react-redux";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import EmptyState from "../../../components/EmptyState/EmptyState";
import PageLoader from "../../../components/PageLoader/PageLoader";
import Select from "react-select";
import {
  useGetLeaveBalances,
  useGetYearListData,
} from "../../../queries/leaveQueries";

const LeaveBalance = () => {
  const user = useSelector((profile) => profile.user.auth);
  const isHr = userRoleAuth(user, 2);
  const isManager = userRoleAuth(user, 4);
  const hrOrManager = isHr || isManager;
  const isDefault = 1;

  const [isPaneOpen, setIsPaneOpen] = useState(false);
  const { data: yearData, isLoading: isYearLoading } = useGetYearListData();

  const yearOptions =
    yearData?.data?.map((year) => ({
      value: year.id,
      label: year.year,
    })) || [];

  const defaultYear = yearOptions.find((option) =>
    yearData?.data?.some(
      (year) => year.id === option.value && year.is_default === isDefault
    )
  );
  const [selectedYear, setSelectedYear] = useState(defaultYear);
  const { data: getLeaveBalanceData, isLoading: isBalancesLoading } =
    useGetLeaveBalances({
      year: selectedYear?.label,
    });
  const leaveBalances = Array.isArray(getLeaveBalanceData?.results)
    ? getLeaveBalanceData?.results
    : [];

  const applyLeave = () => {
    setIsPaneOpen(true);
  };
  useEffect(() => {
    if (defaultYear && !selectedYear) {
      setSelectedYear(defaultYear);
    }
  }, [defaultYear, selectedYear]);
  return (
    <Fragment>
      <ApplyLeaveModal
        open={isPaneOpen}
        close={() => setIsPaneOpen(false)}
        leaveTypes={leaveBalances}
      />
      <div className="flex-1 px-10 pt-8 pb-6">
        <div className="flex items-center justify-between mb-4">
          <div>
            <h2>Leave Balance</h2>
          </div>

          <div className="flex gap-4 items-center">
            <div>
              <button
                className="btn bg-[#3466E7] max-h-[38px] text-sm"
                onClick={applyLeave}
              >
                <span>
                  <i className="ico-plus text-white mr-1 h-3.5 w-3.5"></i>
                </span>
                New Leave Request
              </button>
            </div>
          </div>
        </div>
        <div className="py-3">
          <Select
            className="w-[200px]"
            options={yearOptions}
            isLoading={isYearLoading}
            placeholder="Year"
            value={selectedYear}
            onChange={(option) => setSelectedYear(option)}
          />
        </div>

        {isBalancesLoading ? (
          <PageLoader />
        ) : leaveBalances.length === 0 ? (
          <EmptyState message="No leave balances available" />
        ) : (
          <div className="grid gap-6 grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
            {leaveBalances.map((leave, key) => (
              <LeaveItem leavedata={leave} key={key} />
            ))}
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default LeaveBalance;
