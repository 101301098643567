import React, { useState } from "react";
import dayjs from "dayjs";
import { Timeline } from "antd";
import Pagination from "../../Pagination/Pagination";
import Avatar from "../../Avatar/Avatar";

const EmployeeHistory = ({
  timelineData,
  totalRecords,
  pageNo,
  pageSize,
  setPageNo,
  setPageSize,
}) => {
  const renderUpdates = (updates) => {
    if (typeof updates === "object" && updates !== null) {
      return (
        <div className="pl-3">
          {Object.entries(updates).map(([key, value], index) => {
            const formattedKey =
              key.charAt(0).toUpperCase() + key.slice(1).replace(/_/g, " ");
            const uniqueKey = `${key}_${index}_${Math.floor(
              Math.random() * 10000
            )}`;

            return (
              <span key={uniqueKey} className="block">
                {formattedKey}: {JSON.stringify(value)}
              </span>
            );
          })}
        </div>
      );
    }
    return <p>{JSON.stringify(updates)}</p>;
  };

  const timelineItems = timelineData.map((item, index) => ({
    color: item?.label_color || "blue", // Use the label_color from the server, fallback to blue if undefined
    children: (
      <div>
        <div className="flex justify-between">
          <p className="font-medium">
            {dayjs(item?.date).format("MMM DD YYYY")}
          </p>
          <p className="font-medium">{dayjs(item?.date).format("hh:mm A")}</p>
        </div>
        <div className="flex items-center mb-1">
          <div className="flex items-center gap-3">
            <Avatar title={item?.updated_by?.[0] || ""} size="small" />
            <div className="flex flex-col">
              <div className="flex items-center mb-1 gap-4">
                <div>{item?.updated_by ? "Updated By" : "Updated Type"}</div>
                <div>: {item?.updated_by ? item?.updated_by || "N/A": item?.updated_type || "N/A"}</div>
              </div>
              <div className="flex gap-16">
                <span>Info</span>
                <label className="text-gray-600">: {item?.descriptions || " " }</label>
              </div>
            </div>
          </div>
        </div>

        <div className="flex bg-gray-100 p-2 mt-1 rounded-md text-gray-600 text-xs">
          <div className="flex items-start">
            <div className="flex gap-16">
              <span>Action Taken</span>
              <span>:</span>
            </div>
            <div>{renderUpdates(item.updates)}</div>
          </div>
        </div>
      </div>
    ),
  }));
  return (
    <>
      <div className="mb-20">
        <Timeline>
          {timelineItems.map((item, index) => (
            <Timeline.Item key={index} color={item.color}>
              {item.children}
            </Timeline.Item>
          ))}
        </Timeline>
      </div>
      {timelineData && (
        <Pagination
          className=""
          page={{
            pageNo,
            setPageNo,
            pageSize,
            setPageSize,
            totalRecords,
          }}
        />
      )}
    </>
  );
};

export default EmployeeHistory;
