import React, { useState, useEffect } from "react";
import AddorEditHoliday from "../AddOrEditHoliday/AddorEditHoliday";
import formatDate from "../../../../utils/FormatDate/formatDate";
import DeleteHoliday from "../DeleteHoliday/DeleteHoliday";

const HolidayListData = ({
  data,
  index,
  policyMapping,
  setSelectedPolicy,
  handleMakeApiCallToFetchHolidayList,
}) => {
  const [isEditHoliday, setIsEditHoliday] = useState(false);
  const [isDeleteHoliday, setIsDeleteHoliday] = useState(false);
  useEffect(() => {
    const checkIsIncluded = policyMapping?.some((i) => i.holiday_id === data.holiday_id);
    if (checkIsIncluded) {
      setSelectedPolicy((prev) => ({
        ...prev,
        update: [...prev.update, data.holiday_id],
      }));
    }
  }, [data, policyMapping, setSelectedPolicy]);

  const handleCheck = (e) => {
    const value = e.target.checked;
    const method = policyMapping?.some((i) => i.holiday_id === data.holiday_id) ? "update" : "insert";

    setSelectedPolicy((prev) => ({
      ...prev,
      [method]: value
        ? [...prev[method], data.holiday_id]
        : prev[method].filter((i) => i !== data.holiday_id),
    }));
  };

  const handleDeleteClick = () => {
    setIsDeleteHoliday(true); // Open the DeleteHoliday dialog
  };

  const handleEditClick = () => {
    setIsEditHoliday(true); // Open the AddorEditHoliday dialog
  };

  return (
    <>{
      isEditHoliday &&  
      <AddorEditHoliday
      open={isEditHoliday}
      close={() => setIsEditHoliday(false)}
      existingHolidayData={data}
      handleMakeApiCallToFetchHolidayList={handleMakeApiCallToFetchHolidayList}
    />
    }
     {
      isDeleteHoliday && 
      <DeleteHoliday
      open={isDeleteHoliday}
      close={() => setIsDeleteHoliday(false)}
      holidayId={data.holiday_id}
      handleMakeApiCallToFetchHolidayList={handleMakeApiCallToFetchHolidayList}
    />
     }
     

      <tr className="bg-white border-b hover:bg-gray-50 dark:hover:bg-gray-200" key={index}>
        {setSelectedPolicy && (
          <td className="px-10 py-4">
            <input
              type="checkbox"
              checked={policyMapping?.some((i) => i.holiday_id === data.holiday_id) || false}
              onChange={handleCheck}
            />
          </td>
        )}
        <td className="px-10 py-4">{data.holiday_name}</td>
        <td className="px-10 py-4">{formatDate(data.date)?.dateString2}</td>
        <td className="px-10 py-4">{data.holiday_type_name}</td>
        <td className="px-10 py-4">{data.country_name}</td>
        <td className="py-4 flex items-center justify-center gap-5 border-none">
          <img
            src="/images/edit-black-icon.svg"
            alt="Edit"
            className="cursor-pointer"
            onClick={handleEditClick} // Open edit panel on click
          />
          <img
            src="/images/delete-menu-icon.svg"
            alt="Delete"
            className="cursor-pointer"
            onClick={handleDeleteClick}
          />
        </td>
      </tr>
    </>
  );
};

export default HolidayListData;
