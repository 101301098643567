import React, { useState } from "react";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";

const DeleteHoliday = ({
  open,
  close,
  holidayId,
  handleMakeApiCallToFetchHolidayList,
}) => {
  const axiosPrivate = useAxiosPrivate();

  const [errMsg, setErrMsg] = useState("");

  const handleConfirmDelete = async () => {
    try {
      const response = await axiosPrivate.delete(
        `lms/holiday/deleteHoliday?id=${holidayId}`
      );
      if (response?.data?.status) {
        toast.success(
          response?.data?.message || "Successfully deleted the holiday"
        );
        close();
        handleMakeApiCallToFetchHolidayList();
      } else {
        toast.error(response?.data?.message || "Failed to delete holiday");
      }
    } catch (err) {
      console.error("Error during delete:", err);
      toast.error("Something went wrong, please try again.");
    }
  };

  return (
    <Modal open={open} onClose={close} center>
      <div className="flex items-center justify-center">
        <div>
          <h2 className="text-gray-800 font-medium">Delete Holiday</h2>
          <p>Are you sure you want to delete this holiday?</p>
          {errMsg && <p className="text-red-600">{errMsg}</p>}
          <div className="mt-4 flex justify-end gap-3">
            <button className="btn btn--border" onClick={close}>
              Cancel
            </button>
            <button className="btn btn--red" onClick={handleConfirmDelete}>
              Confirm Delete
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteHoliday;
