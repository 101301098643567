import React, { Fragment, useEffect, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import Line from "../Line";
import Select from "react-select";
import "./ApplyLeaveModal.scss";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import LoadingButton from "../Buttons/LoadingButton";
import { useForm, Controller } from "react-hook-form";
import Avatar from "../Avatar";
import { TimePicker } from "antd";
import dayjs from "dayjs";
import moment, { duration } from "moment";
import { useSelector } from "react-redux";
import {
  useApplyLeave,
  useGetLeaveModeDetails,
  useGetLeaveBalances,
} from "../../queries/leaveQueries";


const ApplyLeaveModal = ({ open, close, leaveTypes }) => {
  const user = useSelector((state) => state.user);
  const userProfile = user?.userProfile;
  const employeeId = userProfile?.id;
  const [managerDetails] = user?.managerDetails;
  const [hrDetails] = user?.hrDetails;
  const lineManagerName = `${managerDetails?.first_name || ""} ${
    managerDetails?.middle_name || ""
  } ${managerDetails?.last_name || ""}`;
  const hrName = `${hrDetails?.first_name || ""} ${
    hrDetails?.middle_name || ""
  } ${hrDetails?.last_name || ""}`;

  const [errMsg, setErrMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
    setValue,
  } = useForm({ mode: "onBlur" });

  useEffect(() => {
    if (!managerDetails || !hrDetails) {
      let message =
        "It seems like the line manager isn't assigned to you. Please contact HR for assistance.";
      if (!hrDetails) {
        message =
          "It seems like the HR manager isn't assigned to you. Please contact the HR department for assistance.";
      }
      setErrMsg(message);
    }
  }, [user]);

  const axiosPrivate = useAxiosPrivate();
  const [leaveTypeId, setLeaveTypeId] = useState(null);
  const [leaveDocument, setLeaveDocument] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromTime, setFromTime] = useState("09:00");
  const [toTime, setToTime] = useState("10:00");
  const [teamList, setTeamList] = useState([]);
  const [totalDurationOfLeaveApplied, setTotalDurationOfLeaveApplied] =
    useState({
      error: false,
      message: "",
      loading: false,
    });

  const [singleDayLeaveDuration, setSingleDayLeaveDuration] = useState(0);
  const [employeeShiftList, setEmployeeShiftList] = useState([]);
  const [firstDate, setFirstDate] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [employeeShiftListUrl, setEmployeeShiftListUrl] = useState("");
  const [holidayList, setHolidayList] = useState([]);
  const { data: leaveModeList, isLoading } = useGetLeaveModeDetails({
    leave_type_id: leaveTypeId,
  });
  const leaveModes = leaveModeList?.data || [];
  const FULL_DAY_MODE = 2;
  const HOURLY_MODE = 1;
  const MULTILE_DAY_MODE = 3;
  const HALF_DAY_MODE = 4;
  const duration_based_shift = 2;
  //
  const singleDayLeaveType = leaveModes.find(
    (mode) => Number(mode.id) === Number(HOURLY_MODE)
  )?.leave_mode_id; //1
  const multipleDayLeaveType = leaveModes.find(
    (mode) => Number(mode.id) === Number(MULTILE_DAY_MODE)
  )?.leave_mode_id; //2
  const halfDayLeaveType = leaveModes.find(
    (mode) => Number(mode.id) === Number(HALF_DAY_MODE)
  )?.leave_mode_id;//3
  const fullDayLeaveType = leaveModes.find(
    (mode) => Number(mode.id) === Number(FULL_DAY_MODE)
  )?.leave_mode_id; //4

  const beforeNoonTime = 5;
  const afterNoonTime = 6;

  const [halfDayType, setHalfDayType] = useState(beforeNoonTime);
  const [leaveType, setLeaveType] = useState("");

  useEffect(() => {
    if (fullDayLeaveType) {
      setLeaveType(fullDayLeaveType);
    } else if (singleDayLeaveType) {
      setLeaveType(singleDayLeaveType);
    } else if (multipleDayLeaveType) {
      setLeaveType(multipleDayLeaveType);
    } else if (halfDayLeaveType) {
      setLeaveType(halfDayLeaveType);
    }
  }, [
    fullDayLeaveType,
    singleDayLeaveType,
    multipleDayLeaveType,
    halfDayLeaveType,
  ]);

  useEffect(() => {
    const delayTImer = setTimeout(() => {
      setTotalDurationOfLeaveApplied((prev) => ({ ...prev, loading: false }));
    }, 1000);
    return () => {
      clearTimeout(delayTImer);
    };
  }, [totalDurationOfLeaveApplied?.loading]);


  const fetchHolidayList = async () => {
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(
        `lms/holiday/holidayList?holiday&page_number=1&page_limit=50&fromDate=${firstDate}&toDate=${lastDate}`
      )
      .then((response) => {
        const res = response?.data;
        if (res.status) {
          setHolidayList(res.holidays);
        } else {
          setHolidayList([]);
        }
      })
      .catch((err) => {
        console.error(err);
        setHolidayList([]);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  const fetchEmployeeShiftList = async () => {
    if (!employeeShiftListUrl || !employeeId || !firstDate || !lastDate) {
      return;
    }
    let isMounted = true;
    const controller = new AbortController();
    await axiosPrivate
      .get(employeeShiftListUrl)
      .then((response) => {
        const res = response?.data;
        setEmployeeShiftList(res.result);
      })
      .catch((err) => {
        console.error(err);
      });

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    if (fromDate) {
      const startDate = moment(fromDate).startOf("month").format("YYYY-MM-DD");
      const endDate = moment(
        Number(leaveType) === singleDayLeaveType ||
          Number(leaveType) === halfDayLeaveType ||
          Number(leaveType) === fullDayLeaveType
          ? fromDate
          : toDate
      )
        .endOf("month")
        .format("YYYY-MM-DD");
      if (firstDate !== startDate) {
        setFirstDate(startDate);
      }
      if (lastDate !== endDate) {
        setLastDate(endDate);
      }
    }
    getTotalDurationOfLeaveApplied();
  }, [fromDate, toDate]);

  useEffect(() => {
    if (firstDate && lastDate) {
      setEmployeeShiftListUrl(
        `attendance/getEmployeeShiftList?fromDate=${firstDate}&toDate=${lastDate}&employeeId=${employeeId}`
      );
    }
  }, [firstDate, lastDate]);

  useEffect(() => {
    if (
      leaveType === singleDayLeaveType ||
      leaveType === halfDayLeaveType ||
      (leaveType === fullDayLeaveType && fromDate) ||
      (fromDate && toDate)
    ) {
      fetchEmployeeShiftList();
      fetchHolidayList();
    }
  }, [employeeShiftListUrl]);

  const clearFormValues = () => {
    setLeaveType(1);
    setLeaveTypeId(null);
    setLeaveDocument(null);
    setFromDate(null);
    setToDate(null);
  };
  // {Onyear chnage}

  const [selectedYear, setSelectedYear] = useState(moment().year());

  const [emloyeeLeaveBalance, setEmloyeeLeaveBalance] = useState(leaveTypes);

  // console and verify data is spreading as expect when yearchnages
  // const selectedYaerLeaveTypes = emloyeeLeaveBalance.filter((i)=>Number(i.year_label) === selectedYear)
  // // console an verify select yearvtype having expected data with selected year

  const { data: getLeaveBalanceData, refetch: refetchGetLeaveBalances } =
    useGetLeaveBalances({
      year: selectedYear,
    });

  const handleFromDateChange = (fromDate) => {
    const selectedDate = fromDate;

    const yearFromSelectedDate = moment(selectedDate).year();

    if (yearFromSelectedDate !== selectedYear) {
  

      setSelectedYear(yearFromSelectedDate);
      const selectedYaerLeaveTypes = emloyeeLeaveBalance.filter(
        (i) => Number(i.year_label) === yearFromSelectedDate
      );

      if (!selectedYaerLeaveTypes.length) {
        refetchGetLeaveBalances({ year: yearFromSelectedDate }).then(
          (newData) => {
            const data = newData?.data?.results || [];

            setEmloyeeLeaveBalance((i) => [...i, ...data]);
          }
        );
      } else {
        console.log("Skiping the api call ");
      }
    } else {
      console.log("Skiping the api call");
    }
  };

  const getMaxDaysLeaveCanApply = () => {
    let availableLeaveBalance = null;
    let isLopLeaveType = false;

    emloyeeLeaveBalance.map((leave) => {
      if (
        leave.leave_type_id === leaveTypeId &&
        leave.year_label === selectedYear
      ) {
        availableLeaveBalance = leave.total_leave;
        isLopLeaveType = leave.is_lop;
      }
    });

    if (availableLeaveBalance === 0 && !isLopLeaveType) {
      toast.info("You don't have enough leave balance");
    }
  };

  useEffect(() => {
    getMaxDaysLeaveCanApply();
  }, [leaveTypeId, emloyeeLeaveBalance]);

  // Can be used later.
  // const getBreakDurationInMinutues = ({ workingDay, fromTime, toTime }) => {
  //   let breakDurationInMinutes = 0;
  //   if (workingDay?.break) {
  //     const { break_from, break_to } = workingDay;
  //     const appliedFromTime = moment(`${fromDate}T${fromTime}`).subtract(
  //       1,
  //       "minutes"
  //     );
  //     const appliedToTime = moment(`${fromDate}T${toTime}`).add(1, "minutes");

  //     const breakFromTime = moment(`${fromDate}T${break_from}`);
  //     const breakToTime = moment(`${fromDate}T${break_to}`);

  //     if (
  //       breakFromTime.isBetween(appliedFromTime, appliedToTime) &&
  //       breakToTime.isBetween(appliedFromTime, appliedToTime)
  //     ) {
  //       breakDurationInMinutes = dayjs(break_to, "HH:mm").diff(
  //         dayjs(break_from, "HH:mm"),
  //         "minutes"
  //       );
  //     } else if (breakFromTime.isBetween(appliedFromTime, appliedToTime)) {
  //       breakDurationInMinutes =
  //         appliedToTime.diff(breakFromTime, "minute") + 1;
  //     } else if (breakToTime.isBetween(appliedFromTime, appliedToTime)) {
  //       breakDurationInMinutes =
  //         appliedFromTime.diff(breakFromTime, "minute") + 1;
  //     }
  //   }
  //   return breakDurationInMinutes;
  // };

  const getTotalDurationOfLeaveApplied = async () => {
    const isSingleDayLeaveType = leaveType === singleDayLeaveType;
    const isHalfDayLeaveType = leaveType === halfDayLeaveType;
    const isFullDayLeaveType = leaveType === fullDayLeaveType;
    const isMultipleDayLeaveType = leaveType === multipleDayLeaveType;
    const isSingleOrHalfOrFullDayLeaveType =
      isSingleDayLeaveType || isHalfDayLeaveType || isFullDayLeaveType;
    const isHalfOrFullDayLeaveType = isHalfDayLeaveType || isFullDayLeaveType;
    const isHourlyOrHalfDayLeaveType =
      isHalfDayLeaveType || isSingleDayLeaveType;

    const duration = {
      error: false,
      message: "",
      loading: true, //set loading true for error parts to avoid the delay in shift validation
    };
    const shiftData = employeeShiftList?.[0]?.shiftDetails || null;
    if (!shiftData?.length) {
      if (isMultipleDayLeaveType && fromDate && toDate) {
        duration.error = true;
        duration.loading = true;
        duration.message =
          "Sorry, you don't have a valid shift for the selected date range. Please ensure your shift schedule is up-to-date before applying for leave. If you need further assistance, please contact HR.";
        return setTotalDurationOfLeaveApplied(duration);
      } else if (!isMultipleDayLeaveType && fromDate) {
        duration.error = true;
        duration.loading = true;
        duration.message =
          "Sorry, you don't have a valid shift for the selected date. Please ensure your shift schedule is up-to-date before applying for leave. If you need further assistance, please contact HR.";
        return setTotalDurationOfLeaveApplied(duration);
      }
      // No shift allocated for this employee
    }
    const leaveTypeDetails = (
      getLeaveBalanceData?.leaveTypes || leaveTypes
    ).find((i) => Number(i.leave_type_id) === Number(leaveTypeId));

    const includeHolidays = leaveTypeDetails?.include_holidays;
    const includeNonWorkingDays = leaveTypeDetails?.include_non_working_days;

    let workingDays = 0;
    if (shiftData) {
      let dates = [];
      if (isSingleOrHalfOrFullDayLeaveType && fromDate) {
        dates.push(fromDate);
      } else if (fromDate && toDate && isMultipleDayLeaveType) {
        moment(fromDate).format("YYYY-MM-DD");
        const differenceInDays =
          moment(toDate).diff(moment(fromDate), "days") + 1;
        for (let i = 0; i < differenceInDays; i++) {
          dates.push(moment(fromDate).add(i, "days").format("YYYY-MM-DD"));
        }
      }
      if (dates.length) {
        dates.forEach((date) => {
          const employeeShift = shiftData.find(
            (i) => moment(i.date).format("YYYY-MM-DD") === date
          );
          const isHoliday = includeHolidays
            ? false
            : holidayList.find(
                (i) => moment(i.date).format("YYYY-MM-DD") === date
              );

          if (!employeeShift) {
            duration.error = true;
            duration.loading = true;
            duration.message =
              "Sorry, you don't have a valid shift for the selected date range. Please ensure your shift schedule is up-to-date before applying for leave. If you need further assistance, please contact HR.";
            setTotalDurationOfLeaveApplied(duration);
          }
          const isWorkingDay =
            includeNonWorkingDays || employeeShift?.isWorkingDay;

          const isHolidayInclude = holidayList.find(
            (i) => moment(i.date).format("YYYY-MM-DD") === date
          );

          if (isWorkingDay) {
            if (
              employeeShift?.isWorkingDay === false &&
              isHourlyOrHalfDayLeaveType
            ) {
              duration.error = true;
              duration.loading = true;
              duration.message =
                "The selected date is a non-working day. Hourly and half-day leave modes are not allowed. Please choose full-day or multiple-day leave modes.";
              return setTotalDurationOfLeaveApplied(duration);
            }
            if (isHolidayInclude && isHourlyOrHalfDayLeaveType) {
              duration.error = true;
              duration.loading = true;
              duration.message =
                "The selected date is a holiday day. Hourly and half-day leave modes are not allowed. Please choose full-day or multiple-day leave modes.";
              return setTotalDurationOfLeaveApplied(duration);
            }

            if (!isHoliday) {
              workingDays += 1;
            }
            if (isSingleOrHalfOrFullDayLeaveType) {
              if (isHoliday) {
                duration.error = true;
                duration.loading = true;
                duration.message =
                  "The requested date falls on a holiday. Please select a different date or consult the holiday calendar.";
                return setTotalDurationOfLeaveApplied(duration);
              }
              const maximumDurationForSingleDay = Number(
                employeeShift.duration
              );
              setSingleDayLeaveDuration(employeeShift);
              const differenceOfTimeInMinitues = dayjs(toTime, "HH:mm").diff(
                dayjs(fromTime, "HH:mm"),
                "minutes"
              );
              const differenceOfTimeInHour = differenceOfTimeInMinitues / 60;

              if (isHalfOrFullDayLeaveType) {
                duration.message =
                  leaveType === halfDayLeaveType
                    ? `Total : ${maximumDurationForSingleDay / 2} Hour(s)`
                    : `Total : 1 Day`;
                duration.error = false;
                duration.loading = false;
              } else if (differenceOfTimeInHour > maximumDurationForSingleDay) {
                duration.message = `Maximum  allowable duration for selected day is ${maximumDurationForSingleDay} hours. `;
                duration.error = true;
                duration.loading = false;
              } else if (Number(differenceOfTimeInHour) < 0) {
                duration.message = `Invalid duration: ${differenceOfTimeInHour} hours. (negative values are not allowed)`;
                duration.error = true;
                duration.loading = false;
              } else {
                duration.message = `Total : ${differenceOfTimeInHour} Hour(s)`;
                duration.error = false;
                duration.loading = false;
              }
            }
          } else if (isSingleOrHalfOrFullDayLeaveType && !isWorkingDay) {
            duration.message =
              "The requested date is not a working day. Please choose a valid working day and try again. ";
            duration.error = true;
            duration.loading = true;
          }
        });
      }
    } else if (isSingleOrHalfOrFullDayLeaveType && fromDate && !shiftData) {
      duration.message =
        "Sorry, you don't have a valid shift for the selected date range. Please ensure your shift schedule is up-to-date before applying for leave. If you need further assistance, please contact HR.";
      duration.error = true;
      duration.loading = true;
    }

    if (isMultipleDayLeaveType && fromDate && toDate) {
      if (!duration.message && workingDays) {
        duration.message = `Total : ${workingDays} day(s)`;
        duration.error = false;
        duration.loading = false;
      } else {
        duration.message =
          "Total working days: 0 day (selected date(s) are not a working day). Please choose a valid working day to proceed.";
        duration.error = true;
        duration.loading = true;
      }
    }

    return setTotalDurationOfLeaveApplied(duration);
  };

  useEffect(() => {
    getTotalDurationOfLeaveApplied();
  }, [employeeShiftList, fromTime, toTime, fromDate, toDate, leaveType]);

  const validateUploadedFile = (e) => {
    const file = e.target.files[0];
    const fileType = file.type;
    const fileSize = file.size;
    if (fileType === "application/pdf") {
      const maxAllowedFileSizeInBytes = 1033414;
      if (fileSize < maxAllowedFileSizeInBytes) {
        setLeaveDocument(file);
      } else {
        toast.error(
          "Uploaded file is too large, Try uploading file less than 1MB"
        );
      }
    } else {
      toast.error("Invalid file type, Accepted file type: PDF");
    }
  };

  // can be used later:
  // const fetchTeamList = async () => {
  //   let isMounted = true;
  //   const controller = new AbortController();
  //   await axiosPrivate
  //     .get(`employee/teams`)
  //     .then((res) => {
  //       const { teamMembers } = res.data;
  //       const team = teamMembers.filter((i, index) => {
  //         return (
  //           index ===
  //           teamMembers.findIndex((memeber) => i.email === memeber.email)
  //         );
  //       });
  //       const filteredTeam = team.filter((i) => i.email !== employeeEmail);
  //       setTeamList(filteredTeam);
  //     })
  //     .catch((err) => {
  //     });
  //   return () => {
  //     isMounted = false;
  //     controller.abort();
  //   };
  // };

  const authState = useSelector((state) => state.user);
  const { settingValue } = authState;
  const HrActionView = settingValue.isHrLeaveApprovalRequired;
  const fetchNotifyTeamMemberList = async () => {
    let url = "lms/leave/notifyTeamMemberList?pageSize=500";

    let isMounted = true;
    const controller = new AbortController();
    setTeamList([]);
    const res = await axiosPrivate
      .get(url, { signal: controller.signal })
      .then((res) => res)
      .catch((err) => err);
    if (res?.data?.status) {
      const data = res?.data?.employee || [];
      const formattedData = data.map((i) => {
        const empLabel = (
          <div className="flex items-center gap-2">
            <Avatar image={i?.photo} width={"6"} height={"6"} />
            <p>{`${i?.first_name || ""} ${i?.middle_name || ""} ${
              i?.last_name || ""
            }`}</p>
          </div>
        );
        return {
          label: empLabel,
          value: i.email,
        };
      });
      setTeamList(formattedData);
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  };

  useEffect(() => {
    if (open) {
      const debouce = setTimeout(() => {
        fetchNotifyTeamMemberList();
      }, 500);
      return () => {
        clearTimeout(debouce);
      };
    }
  }, [open]);

  const { mutate: mutateApplyLeave, isPending } = useApplyLeave();
  const handleClosePanel = () => {
    clearFormValues();
    reset();
    close();
  };
  const applyLeave = async (data) => {
    setErrMsg("");
    if (!managerDetails || !hrDetails) {
      let message =
        "It seems like the line manager isn't assigned to you. Please contact HR for assistance.";
      if (!hrDetails) {
        message =
          "It seems like the HR manager isn't assigned to you. Please contact the HR department for assistance.";
      }
      toast.info(message);

      return;
    }
    if (totalDurationOfLeaveApplied.error) {
      return toast.error(totalDurationOfLeaveApplied.message);
    }
    const notifyTeamMembers =
      data?.notifyTeamMembers?.map((i) => i?.value) || [];

    let leaveModeId = HOURLY_MODE;
    let leaveApplicationFromTime = fromTime;
    let leaveApplicationToTime = toTime;
    if (
      Number(leaveType === singleDayLeaveType) ||
      Number(leaveType === halfDayLeaveType)
    ) {
      leaveModeId = HOURLY_MODE;
      data.to_date = data.from_date;
      if (Number(leaveType === halfDayLeaveType)) {
        // for duration based shift before and after noon is not applicabile for employee,
        // In that case hide the before noon and after noon for half day leave mode
        // Instaed handle duration by totalWorkDureation for that day / 2
        if (
          Number(singleDayLeaveDuration.shift_type) ===
          Number(duration_based_shift)
        ) {
          leaveApplicationFromTime = null;
          leaveApplicationToTime = null;
          singleDayLeaveDuration.duration =
            Number(singleDayLeaveDuration.duration) / 2;
        } else if (halfDayType === beforeNoonTime) {
          leaveApplicationFromTime = singleDayLeaveDuration?.time_from || "";
          leaveApplicationToTime = singleDayLeaveDuration?.time_to || "";
          leaveApplicationFromTime = singleDayLeaveDuration?.time_from || "";
          leaveApplicationToTime = dayjs(
            singleDayLeaveDuration?.time_from,
            "HH:mm:ss"
          )
            .add(Number(singleDayLeaveDuration?.duration) / 2, "hour")
            .format("HH:mm:ss");
        } else {
          leaveApplicationFromTime = dayjs(
            singleDayLeaveDuration?.time_to,
            "HH:mm:ss"
          )
            .subtract(Number(singleDayLeaveDuration?.duration) / 2, "hour")
            .format("HH:mm:ss");
          leaveApplicationToTime = singleDayLeaveDuration?.time_to || "";
        }
      }
    } else {
      leaveModeId = FULL_DAY_MODE;
      if (Number(leaveType === fullDayLeaveType)) {
        data.to_date = data.from_date;
      }
    }
    if (leaveTypeId) {
      const formData = new FormData();
      leaveDocument && formData.append("file", leaveDocument);
      formData.append("leave_reason", data.reason);
      formData.append("to_date", data.to_date);
      formData.append("from_date", data.from_date);
      formData.append("leave_mode", leaveModeId);
      formData.append("leave_type_id", leaveTypeId);
      formData.append("fromTime", leaveApplicationFromTime || "");
      formData.append("toTime", leaveApplicationToTime || "");
      formData.append("notifyTeamMembers", notifyTeamMembers || []);
      mutateApplyLeave(formData, {
        onSuccess: (data) => {
          if (data?.status) {
            toast.success(data.message);
            handleClosePanel();
          }
        },
        onError: (error) => {
          const responseMsg =
            error?.data?.message ||
            error?.response?.data?.message ||
            "Failed to submit leave application. Please try again later.";

          toast.error(responseMsg);
        },
      });
    } else {
      toast.info(
        "Please select a leave type before submitting your application."
      );
    }
  };

  const handleChangeTime = (e, source) => {
    if (source === "fromTime") {
      setFromTime(dayjs(e).format("HH:mm"));
    } else {
      setToTime(dayjs(e).format("HH:mm"));
    }
  };

  const handleSetLeaveTypeId = (e) => {
    setValue("from_date", "");
    setValue("to_date", "");
    setLeaveTypeId(e.value);
    setFromDate(null);
    setToDate(null);
    setLeaveType(fullDayLeaveType);
  };

  const options =
    emloyeeLeaveBalance
      ?.filter((leave) => leave.year_label === selectedYear)
      .map((leave, index) => ({
        value: leave.leave_type_id,
        label: leave.leave_name,
        key: index,
      })) || [];

  const filteredLeaveData = emloyeeLeaveBalance?.find(
    (leaveDetails) =>
      leaveDetails?.leave_type_id === leaveTypeId &&
      leaveDetails?.year_label === selectedYear
  );
 


  const leaveBalanceDays = filteredLeaveData?.leave_balance_days;
  const leaveBalanceHours = filteredLeaveData?.leave_balance_hours
  const formatLeaveBalance = `${leaveBalanceDays || 0} days & ${ leaveBalanceHours || 0} hours remaining`

 const isLop = filteredLeaveData?.is_lop
 
  return (
    <Fragment>
      <SlidingPane isOpen={open} width="480px" onRequestClose={close}>
        <form onSubmit={handleSubmit(applyLeave)}>
          <div>
            <div>
              <h2 className="pb-3 pl-5">Apply Leave</h2>
            </div>

            <Line />

            <div className="px-5">
              <span className="text-red-600 font-medium">{errMsg}</span>
              <div className="p-1 font-medium text-gray-800">
                Leave type <span className="text-red-600 font-medium">*</span>
              </div>

              <div>
                <Select
                  options={options}
                  onChange={handleSetLeaveTypeId}
                  placeholder="Select Leave Type"
                />
                <div className="bg-[#F9FAFB] mt-3 flex rounded-md">
                  {emloyeeLeaveBalance?.length === 0 ? (
                    <p className="flex p-1 ml-2">No leave balances present</p>
                  ) : leaveTypeId ? (
                    filteredLeaveData  ? (
                   <div>
                    {!isLop && (
                      <p
                      className="flex p-1 ml-2"
                     
                    >
                      {formatLeaveBalance}
                    </p>
                    )}
                    </div>
                    ) : (
                      <p className="text-red-600 font-medium">
                        Selected leave type is not allocated with the leave policy for {selectedYear}
                      </p>
                    )
                  ) : null}
                </div>
              </div>
              {isLoading ? (
                <div className="mt-2">Loading leave modes...</div>
              ) : leaveTypeId ? (
                <>
                  {leaveTypeId && leaveModes.length === 0 && (
                    <p className="text-red-600 font-medium">
                      This leave type does not have any assigned leave modes.
                    </p>
                  )}
                  {!filteredLeaveData?.is_lop && leaveBalanceDays === 0 && leaveBalanceHours === 0 ? null :   <div className="flex my-2 gap-3">
                    {fullDayLeaveType && (leaveBalanceDays > 0 || filteredLeaveData?.is_lop)  && (
                      <div className="flex items-center bg-gray-100 rounded-md p-1">
                        <input
                          type="radio"
                          id="full-day"
                          name="leave-mode"
                          value={fullDayLeaveType}
                          checked={leaveType === fullDayLeaveType}
                          onChange={() => setLeaveType(fullDayLeaveType)}
                        />
                        <label
                          htmlFor="full-day"
                          className="ml-2 mt-1 text-sm font-medium text-gray-900"
                        >
                          Full day
                        </label>
                      </div>
                    )}
                    {singleDayLeaveType && (
                      <div className="flex items-center bg-gray-100 rounded-md p-1">
                        <input
                          type="radio"
                          id="single-day-hourly"
                          name="leave-mode"
                          value={singleDayLeaveType}
                          checked={leaveType === singleDayLeaveType}
                          onChange={() => setLeaveType(singleDayLeaveType)}
                        />
                        <label
                          htmlFor="single-day-hourly"
                          className="ml-2 mt-1 text-sm font-medium text-gray-900"
                        >
                          Hourly
                        </label>
                      </div>
                    )}
                    {multipleDayLeaveType && (leaveBalanceDays > 1 || filteredLeaveData?.is_lop) &&  (
                      <div className="flex items-center bg-gray-100 rounded-md p-1">
                        <input
                          type="radio"
                          id="multiple-day"
                          name="leave-mode"
                          value={multipleDayLeaveType}
                          checked={leaveType === multipleDayLeaveType}
                          onChange={() => setLeaveType(multipleDayLeaveType)}
                        />
                        <label
                          htmlFor="multiple-day"
                          className="ml-2 mt-1 text-sm font-medium text-gray-900"
                        >
                          Multiple day
                        </label>
                      </div>
                    )}
                    {halfDayLeaveType &&(
                      <div className="flex items-center bg-gray-100 rounded-md p-1">
                        <input
                          type="radio"
                          id="half-day"
                          name="leave-mode"
                          value={halfDayLeaveType}
                          checked={leaveType === halfDayLeaveType}
                          onChange={() => setLeaveType(halfDayLeaveType)}
                        />
                        <label
                          htmlFor="half-day"
                          className="ml-2 mt-1 text-sm font-medium text-gray-900"
                        >
                          Half day
                        </label>
                      </div>
                    )}
                  </div>}
                

                  {/* Date Section */}
                  {leaveTypeId && leaveModes.length > 0 && (
                    <div className="pt-2 pb-1 font-medium">
                      <p className="text-gray-800">
                        Date <span className="text-red-600 font-medium">*</span>
                      </p>
                    </div>
                  )}

                  {/* Mode by Dates */}
                  {leaveTypeId && leaveModes.length > 0 && (
                    <div className="flex w-full">
                      <div
                        className={`flex flex-col ${
                          leaveType === singleDayLeaveType ||
                          leaveType === halfDayLeaveType ||
                          leaveType === fullDayLeaveType
                            ? "w-full"
                            : "w-3/5"
                        }`}
                      >
                        {/* Date Picker */}
                        <div className="flex items-center gap-2">
                          <input
                            type="date"
                            placeholder="enter the date"
                            {...register("from_date", {
                              required: "This field is required",
                              onChange: (e) => {
                                const value = e.target.value;
                                setFromDate(value);
                                handleFromDateChange(value);
                              },
                            })}
                          />
                          {/* Single Day Leave Time Picker */}
                          {leaveType === singleDayLeaveType && (
                            <>
                              <Controller
                                control={control}
                                name="fromTime"
                                render={({ field }) => (
                                  <TimePicker
                                    {...field}
                                    disabled={!fromDate}
                                    allowClear={false}
                                    changeOnBlur
                                    size="large"
                                    format="hh:mm a"
                                    minuteStep={60}
                                    className="min-w-[110px]"
                                    defaultValue={dayjs("09:00", "hh:mm")}
                                    onChange={(e) =>
                                      handleChangeTime(e, "fromTime")
                                    }
                                  />
                                )}
                              />
                              <p className="text-gray-800 font-medium">to</p>
                              <Controller
                                control={control}
                                name="toTime"
                                render={({ field }) => (
                                  <TimePicker
                                    {...field}
                                    allowClear={false}
                                    changeOnBlur
                                    size="large"
                                    format="hh:mm a"
                                    minuteStep={60}
                                    className="min-w-[110px]"
                                    defaultValue={dayjs("10:00", "hh:mm")}
                                    onChange={(e) =>
                                      handleChangeTime(e, "toTime")
                                    }
                                    disabled={!fromDate}
                                  />
                                )}
                              />
                            </>
                          )}
                        </div>
                        {/* Validation Messages */}
                        {errors?.from_date && (
                          <span className="text-red-500 text-xs mt-1 ml-1">
                            {errors.from_date.message}
                          </span>
                        )}
                        {errors?.fromTime && (
                          <span className="text-red-500 text-xs mt-1 ml-1">
                            {errors.fromTime.message}
                          </span>
                        )}
                      </div>

                      {/* Multiple Day Leave */}
                      {leaveType === multipleDayLeaveType && (
                        <>
                          <div className="mx-3 flex justify-center font-medium text-gray-800 items-center">
                            to
                          </div>
                          <div className="flex flex-col w-3/5">
                            <input
                              type="date"
                              placeholder="enter the date"
                              min={fromDate}
                              disabled={!fromDate}
                              {...register("to_date", {
                                required: "This field is required",
                                onChange: (e) => setToDate(e.target.value),
                              })}
                            />
                            {errors?.to_date && (
                              <span className="text-red-500 text-xs mt-1 ml-1">
                                {errors.to_date.message}
                              </span>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  )}
                  {/* Total Duration */}
                  {totalDurationOfLeaveApplied?.message &&
                    (totalDurationOfLeaveApplied.loading ? (
                      <div className="inline-flex w-full">
                        <LoadingButton
                          iconWidth="4"
                          iconHeight="4"
                          className="h-7 w-full my-3 btn--full text-black"
                          bg="bg-[#ECFDF5]"
                        />
                      </div>
                    ) : (
                      <div
                        className={`mt-3 inline-flex w-full ${
                          totalDurationOfLeaveApplied.error
                            ? "bg-red-500 hover:bg-red-600 text-white"
                            : "bg-[#ECFDF5]"
                        } rounded-md`}
                      >
                        {!totalDurationOfLeaveApplied.error && (
                          <img
                            className="w-4 h-4 mt-1.5 ml-2"
                            src="/images/Icon.png"
                            alt=""
                          />
                        )}
                        <p className="p-1 ml-2">
                          {totalDurationOfLeaveApplied.message}
                        </p>
                      </div>
                    ))}
                  {/* Not see for duration-based shifts */}
                  {leaveType === halfDayLeaveType &&
                  fromDate &&
                  singleDayLeaveDuration?.time_to &&
                  singleDayLeaveDuration?.time_from ? (
                    <div className="flex gap-5 py-3">
                      <div className="flex items-center bg-gray-100 rounded-md p-1">
                        <input
                          type="radio"
                          id="befornoon"
                          name="beforennoon"
                          checked={
                            halfDayType === beforeNoonTime ? true : false
                          }
                          onChange={() => {
                            setHalfDayType(beforeNoonTime);
                          }}
                        />
                        <label
                          htmlFor="befornoon"
                          className="ml-2 mt-1 text-sm font-medium text-gray-900 "
                        >
                          Before Noon
                        </label>
                      </div>
                      <div className="flex items-center bg-gray-100 rounded-md p-1">
                        <input
                          type="radio"
                          id="adternoon"
                          name="afternoon"
                          checked={halfDayType === afterNoonTime ? true : false}
                          onChange={() => {
                            setHalfDayType(afterNoonTime);
                          }}
                        />
                        <label
                          htmlFor="adternoon"
                          className="ml-2 mt-1 text-sm font-medium text-gray-900 "
                        >
                          After Noon
                        </label>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <p>Please select a leave type to continue...</p>
              )}

              <div className="py-3">
                <p className="pb-1 font-medium text-gray-800">
                  Notify team member
                </p>

                <Controller
                  control={control}
                  name="notifyTeamMembers"
                  render={({ field }) => {
                    return (
                      <Select {...field} isMulti={true} options={teamList} />
                    );
                  }}
                />
              </div>
              <div>
                <p className="pb-1 font-medium text-gray-800">
                  Add note <span className="text-red-600 font-medium">*</span>
                </p>
                <div>
                  <textarea
                    className="h-[96px]"
                    placeholder="please tell us the reason"
                    {...register("reason", {
                      required: "This field is required",
                    })}
                  ></textarea>
                  {errors?.reason && (
                    <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
                      {errors.reason.message}
                    </span>
                  )}
                </div>
              </div>
              <div>
                <p className="pt-4 pb-1 font-medium text-gray-800">
                  Upload attachment{" "}
                </p>
                <div>
                  <input
                    type="file"
                    id="lv-doc"
                    accept="application/pdf"
                    className="hidden"
                    onChange={(e) => {
                      validateUploadedFile(e);
                    }}
                  ></input>

                  <label
                    htmlFor="lv-doc"
                    className="btn btn--border w-full h-[38px] mt-2"
                  >
                    {" "}
                    <i className="ico-upload mr-3 text-lg text-gray-900"></i>
                    Upload document
                  </label>
                </div>
                <p className="mt-2 text-sm">
                  Max file size: 1MB. File format: pdf
                </p>
                {leaveDocument && (
                  <p className="mt-2 text-sm">
                    Uploaded file: {leaveDocument.name}
                  </p>
                )}
                {managerDetails && (
                  <div className="mt-6 p-2 bg-yellow-50 flex flex-col gap-3 rounded-md border border-yellow-500">
                    <div className="flex items-center">
                      <span className="mr-4 text-yellow-400">
                        <svg
                          className="fill-yellow-400"
                          width="16"
                          height="17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16 8.259a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1-9a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0v-4a1 1 0 0 0-1-1Z"
                          />
                        </svg>
                      </span>
                      <p>Line Manager: &nbsp;</p>
                    </div>

                    <div className="flex items-center gap-2 border border-yellow-600 p-2 rounded-full">
                      <Avatar
                        title={`${
                          managerDetails?.first_name?.slice?.(0, 1) || ""
                        }${managerDetails?.last_name?.slice?.(0, 1) || ""}`}
                      />
                      <div>
                        <p className="text-yellow-800 font-medium">
                          {lineManagerName}
                        </p>
                        <span>{managerDetails?.designation_title || ""}</span>
                      </div>
                    </div>
                  </div>
                )}
                {hrDetails && HrActionView && (
                  <div className="mt-6 p-2 bg-yellow-50 flex flex-col gap-3 rounded-md border border-yellow-500">
                    <div className="flex items-center">
                      <span className="mr-4 text-yellow-400">
                        <svg
                          className="fill-yellow-400"
                          width="16"
                          height="17"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16 8.259a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-7 4a1 1 0 1 1-2 0 1 1 0 0 1 2 0Zm-1-9a1 1 0 0 0-1 1v4a1 1 0 1 0 2 0v-4a1 1 0 0 0-1-1Z"
                          />
                        </svg>
                      </span>
                      <p>HR: &nbsp;</p>
                    </div>

                    <div className="flex items-center gap-2 border border-yellow-600 p-2 rounded-full">
                      <Avatar
                        title={`${hrDetails?.first_name?.slice?.(0, 1) || ""}${
                          hrDetails?.last_name?.slice?.(0, 1) || ""
                        }`}
                      />
                      <div>
                        <p className="text-yellow-800 font-medium">{hrName}</p>
                        <span>{hrDetails?.designation_title || ""}</span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div></div>
          </div>
          <div className="mt-40">
            <Line />
          </div>

          <div className="flex justify-end items-end px-5">
            <div className="mr-2">
              <button
                type="button"
                className="btn btn--border text-[14px]"
                onClick={handleClosePanel}
              >
                Cancel
              </button>
            </div>
            <div>
              {!isPending ? (
                <button
                  type="submit"
                  className=" btn text-[14px] bg-[#3466E7] pt-[11px]"
                >
                  Submit
                </button>
              ) : (
                <LoadingButton title={"Submitting leave application"} />
              )}
            </div>
          </div>
        </form>
      </SlidingPane>
    </Fragment>
  );
};

export default ApplyLeaveModal;
