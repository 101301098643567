import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { formatSearchParams } from "../utils/helperFunctions";
import queryKeys from "./queryKeys";

const { holidayDataList,holidayDetails,editHoliday,addHoliday } = queryKeys

export const useHolidayDataList = (params = {}) => {
    const formattedParams = formatSearchParams(params);
    const axiosPrivate = useAxiosPrivate();
    return useQuery({
      queryKey: [holidayDataList, params],
      queryFn: async () => {
        const { data } = await axiosPrivate.get("lms/holiday/holidayList", {
          params: formattedParams,
        });
        return data;
      },
    });
  };
  
  export const useHolidayDetail = (params = {}) => {
    const formattedParams = formatSearchParams(params);
    const axiosPrivate = useAxiosPrivate();
    return useQuery({
      queryKey: [holidayDetails, params],
      queryFn: async () => {
        const { data } = await axiosPrivate.get("lms/holiday/getHolidayDetails", {
          params: formattedParams,
        });
        return data;
      },
      enabled: !!params?.id,
    });
  };

  export const useEditHolidayData = () => {
    const queryClient = useQueryClient();
    const axiosPrivate = useAxiosPrivate();
  
    return useMutation({
      mutationKey: [editHoliday], 
      mutationFn: async (payload) => {
        const { data } = await axiosPrivate.put('lms/holiday/editHoliday', payload);
        return data;
      },
      onSuccess: (data) => {
        if (data?.status) {
          queryClient.invalidateQueries([holidayDataList]);
        }
      },
      onError: (error) => {
        
      },
    });
  };
  
  export const useAddNewHoliday = () => {
      const queryClient = useQueryClient();
      const axiosPrivate = useAxiosPrivate();
    
      return useMutation({
        mutationKey: [addHoliday], 
        mutationFn: async (payload) => {
          const { data } = await axiosPrivate.post('lms/holiday/addHoliday', payload);

          return data;
          
        },
        onSuccess: (data) => {
          if (data?.status) {
            queryClient.invalidateQueries([holidayDataList]);
          } 
        },
        onError: (error) => {
          
        },
      });
    };
 