import React from "react";
import DashboardLayout from "../../layouts/DashboardLayout/DashboardLayout";
import userRoleAuth from "../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../config/userRoles";

const menu = [
  {
    text: "Widgets",
    link: `/settings/widget-list`,
    icon: "ico-down",
    roles: [userRoles.HR, userRoles.TENANTADMIN],
    status: true,
  },
  {
    text: "Tenant Setting",
    link: `/settings/tenant-setting`,
    icon: "ico-down",
    roles: [userRoles.HR, userRoles.TENANTADMIN],
    status: true,
  },
  {
    text: "App Setting",
    link: `/settings/app-setting`,
    icon: "ico-down",
    roles: [userRoles.HR, userRoles.TENANTADMIN],
    status: true,
  },
  {
    text: "Logs",
    icon: "ico-down",
    roles: [userRoles.HR, userRoles.TENANTADMIN],
    link: `/settings/leave-policy-renewal-logs`,
    menu: [
      {
        text: "Policy Renewal Logs",
        link: `/settings/leave-policy-renewal-logs`,
        roles: [userRoles.HR, userRoles.TENANTADMIN],
      },
      {
        text: "Auto Increment Logs",
        link: `/settings/leave-auto-increment-logs`,
        roles: [userRoles.HR, userRoles.TENANTADMIN],
      },
    ],
    status: true,
  },
];

const SettingsModule = () => {
  return (
    <DashboardLayout asideBarMenu={menu} />
  );
};

export default SettingsModule;
