import "./App.scss";
import React from "react";
import { Routes, Route } from "react-router-dom";
import Start from "./containers/Offer/Start";
import Main from "./layouts/Main";
import CreateAccount from "./containers/Offer/CreateAccount";
import Offer from "./containers/Offer";
import Welcome from "./containers/Offer/AboutCompany";
import PersonalDetails from "./containers/Offer/PersonalDetails";
import OfferDetails from "./containers/Offer/OfferDetails";
import SupportingFiles from "./containers/Offer/SupportingFiles";
import RegistrationCompleted from "./containers/Offer/RegistrationCompleted";
import Login from "./containers/Login";
import Activation from "./containers/Onboarding/Activation";
import WelcomeEmployee from "./containers/Onboarding/Welcome/Welcome";
import AboutCompany from "./containers/Onboarding/AboutCompany/AboutCompany";
import KnowTheJob from "./containers/Onboarding/KnowTheJob/KnowTheJob";
import GreetTeam from "./containers/Onboarding/GreetTeam/GreetTeam";
import MeetTeam from "./containers/Onboarding/MeetTeam/MeetTeam.js";
import Dashboard from "./layouts/Dashboard";
import EmployeePortal from "./containers/Dashboard/EmployeePortal";
import EmployeeList from "./containers/Employee/ManageEmployee/EmployeeList";
import Employee from "./containers/Employee";
import Unauthorized from "./components/Unauthorized";
import RequireAuth from "./components/RequireAuth";
import Onboarding from "./containers/Onboarding";
import StarterLayout from "./layouts/StarterLayout";
import PersistLogin from "./components/PersistLogin";
import ForgotPassword from "./containers/Login/ForgotPassword";
import ResetPassword from "./containers/Login/ResetPassword";
import AddEmployeeBasics from "./containers/Employee/ManageEmployee/CreateEmployee/AddEmployeeBasic/AddEmployeeBasics";
import EmployeePersonalDetails from "./containers/Employee/ManageEmployee/CreateEmployee/EmployeePersonalDetails/EmployeePersonalDetails";
import EmployeeContactDetails from "./containers/Employee/ManageEmployee/CreateEmployee/EmployeeContactDetails/EmployeeContactDetails";
import CandidateList from "./containers/Candidate/CandidateList/CandidateList";
import CreateCandidate from "./containers/Candidate/CreateCandidate/CreateCandidate";
import Leaves from "./containers/Leaves";
import MyLeave from "./containers/Leaves/MyLeave/MyLeave";
import LeaveBalance from "./containers/Leaves/LeaveBalance";
import HolidayCalendar from "./containers/Leaves/HolidayCalendar";
import LeaveCalendar from "./containers/Leaves/LeaveCalendar";
import EmployeeLeave from "./containers/Leaves/EmployeeLeave/EmployeeLeave";
import EmployeeOverview from "./containers/Employee/Overview";
import EmployeeDetailsLayout from "./layouts/EmployeeDetailsLayout";
import SingleEmployeeDetails from "./components/Employee/SingleEmployeeDetails";
import EmployeeDirectory from "./containers/Employee/ManageEmployee/EmployeeDirectory";
import Assets from "./containers/Assets";
import MyAssets from "./components/Assets/MyAssets";
import ManageAssets from "./components/Assets/ManageAssets/ManageAsset";
import EmployeeAssets from "./components/Assets/EmployeeAssets/EmployeeAsset";
import Organization from "./containers/Organization/Organization";
import OrganizationLocation from "./containers/Organization/OrganizationLocation";
import OrganizationDepartment from "./containers/Organization/OrganizationDepartment";
import OrganizationDesignation from "./containers/Organization/OrganizationDesignation";
import Payroll from "./containers/Payroll";
import Payslip from "./containers/Payroll/Payslip";
import PayrollOverview from "./containers/Payroll/PayrollOverview";
import PayrollOrganizationTax from "./containers/Payroll/OrganizationTax";
import PayrollSaturaryComponents from "./containers/Payroll/PayrollStatutoryComponents";
import PayrollSalaryComponents from "./containers/Payroll/PaySalaryComponent";
import PayrollSalaryTemplate from "./containers/Payroll/SalaryTemplates";
import PayrollPaymentSchedule from "./containers/Payroll/PaymentSchedule";
import PayrollPreferences from "./containers/Payroll/PreferenceSettings";
import Attendance from "./containers/Attendance/AttendanceModule";
import MyAttendance from "./containers/Attendance/SelfService/MyAttendance/MyAttendanceList";
import EmployeeAttendance from "./containers/Attendance/ManageAttendance/EmployeeAttendance/EmployeeAttendance";
import ManageShifts from "./containers/Attendance/ManageShift/ShiftSchedule";
import TalentPool from "./containers/Employee/Recruitment";
import JobOpening from "./containers/Employee/Recruitment/JobOpening";
import RecruitmentOffer from "./containers/Employee/Recruitment/Offer";
import Task from "./containers/Task";
import TaskLeaveApplications from "./containers/Task/TaskLeaveApplications";
import HolidayList from "./components/Leaves/Holiday/HolidayList/HolidayList";
// import ManageEmployeeLeave from "./components/Leaves/ManageEmployeeLeave/EmployeeLeave/EmployeeLeave";
import CustomizeLeaveBalance from "./containers/Leaves/Settings/CustomizeBalance/CustomizeBalance";
import LeaveBalanceAutoIncrement from "./components/Leaves/Settings/LeaveType/LeaveBalanceAutoIncrement.js";
import OrganizationTree from "./containers/Employee/ManageEmployee/OrganizationTree";
import Seperation from "./containers/Employee/ManageEmployee/Seperation";
import LeaveType from "./containers/Leaves/Settings/LeaveType/LeaveType";
import CustomizePolicy from "./containers/Leaves/Settings/CustomizePolicy/CustomizePolicy";
import MyRegularizationRequest from "./containers/Attendance/SelfService/MyRegularizationRequest/MyRegularizationRequest";
import WhoIsIn from "./containers/Attendance/SelfService/WhoIsIn/WhoIsIn";
import MyShifts from "./containers/Attendance/SelfService/MyShifts/MyShifts";
import Overview from "./containers/Attendance/ManageAttendance/Overview/Overview";
import EmployeeAttendanceDetails from "./components/Attendance/ManageAttendance/EmployeeAttendance/EmployeeAttendanceDetails/EmployeeAttendanceDetails";
import RegularizationApproval from "./containers/Attendance/ManageAttendance/RegularizationApproval/RegularizationApproval";
import FinalizeAttendance from "./containers/Attendance/ManageAttendance/FinalizeAttendance/FinalizeAttendance";
import ViewEmployeeFinalizeAttendance from "./components/Attendance/ManageAttendance/FinalizeAttendance/ViewEmployeeFinalizeAttendance";
import ShiftCalendar from "./containers/Attendance/ManageShift/ShiftCalendar";
import AssignShift from "./containers/Attendance/ManageShift/AssignShift";
import LeaveOverView from "./containers/Leaves/ManageLeave/Overview/Overview";
import EmployeeLeaveCalendar from "./containers/Leaves/SelfServices/EmployeeLeave/EmployeeLeaveCalendar";
import CandidateModule from "./containers/Candidate";
import CustomizeWidgetList from "./containers/Dashboard/EmployeePortal/CustomizeDashboardWidget/customiseWidgetList";
import WidgetSettings from "./components/Settings/AssignDashboardWidget/Settings";
import AssignWidget from "./components/Settings/AssignDashboardWidget/AssignWidget";
import SettingsModule from "./containers/Settings/SettingsModule.js";
import TenantSetting from "./containers/Settings/TenantSetting";
import AppSetting from "./components/Settings/AppSetting/AppSetting.js";
import userRoles from "./config/userRoles.js";
import PolicyApproval from "./containers/Task/Approval/Policy/PolicyApproval.js";
import PolicyModule from "./containers/Policy/PolicyModule.js";
import PolicyList from "./containers/Policy/PolicyList.js";
import LeavePolicyCornLogs from "./components/Settings/CornLogs/LeavePolicyCornLogs.js"
import LeaveAutoIncrementLogs from "./components/Settings/CornLogs/LeaveAutoIncrementLogs.js"

const ROLES = {
  superAdmin: userRoles.TENANTADMIN,
  Hr: userRoles.HR,
  systemAdmin: 3,
  employee: userRoles.EMPLOYEE,
  Manager: userRoles.MANAGER,
};

function App() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route path="login" element={<Login />} />
      <Route path="onboarding/activation/:token" element={<Activation />} />

      <Route element={<StarterLayout />}>
        <Route path="offer/:token" element={<Start />} />
        <Route path="offer" element={<Offer />}>
          <Route path="create-account" element={<CreateAccount />} />
          <Route path="about" element={<Welcome />} />
          <Route path="personal-details" element={<PersonalDetails />} />
          <Route path="offer-details" element={<OfferDetails />} />
          <Route path="supporting-files" element={<SupportingFiles />} />
          <Route
            path="registration-completed"
            element={<RegistrationCompleted />}
          />
          <Route path="login" element={<Login />} />
        </Route>

        {/* ONBOARDING ROUTES STARTS*** */}

        <Route element={<PersistLogin />}>
          <Route path="onboarding" element={<Onboarding />}>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.employee,
                    ROLES.Hr,
                    ROLES.Manager,
                    ROLES.superAdmin,
                    ROLES.systemAdmin,
                  ]}
                />
              }
            >
              <Route path="onboard-final" element={<WelcomeEmployee />} />
              <Route path="about-company" element={<AboutCompany />} />
              <Route path="know-the-job" element={<KnowTheJob />} />
              <Route path="greet-team" element={<GreetTeam />} />
              <Route path="meet-team" element={<MeetTeam />} />
            </Route>
          </Route>
        </Route>
        {/* <Route path="login" element={<Login />} /> */}
        <Route path="unauthorized" element={<Unauthorized />} />
      </Route>

      {/* ONBOARDING ROUTES ENDS*** */}

      {/*EMPLOYEE MODULE ROUTE STARTS*** */}

      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.Hr,
                ROLES.Manager,
                ROLES.superAdmin,
                ROLES.systemAdmin,
              ]}
            />
          }
        >
          <Route path="/" element={<Main />}>
            <Route path="employee-module" element={<Employee />}>
              <Route path="employee-list" element={<EmployeeList />} />
              <Route path="create-employee" element={<AddEmployeeBasics />} />
              <Route
                path="employee-personal-details"
                element={<EmployeePersonalDetails />}
              />
              <Route
                path="employee-contact-details"
                element={<EmployeeContactDetails />}
              />
            </Route>
          </Route>
        </Route>
      </Route>

      {/*EMPLOYEE MODULE ROUTE ENDS*** */}

      {/* DASHBOARD ROUTE STARTS*** */}

      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.employee,
                ROLES.Hr,
                ROLES.Manager,
                ROLES.superAdmin,
                ROLES.systemAdmin,
              ]}
            />
          }
        >
          <Route path="dashboard" element={<Dashboard />}>
            <Route path="" element={<EmployeePortal />} />
            <Route
              path="employee-settings"
              element={<SingleEmployeeDetails />}
            />
            <Route
              path="/dashboard/customize-widget-list"
              element={<CustomizeWidgetList />}
            />
          </Route>
        </Route>
      </Route>

      {/* DASHBOARD ROUTE ENDS*** */}

      {/* CANDIDATE ROUTE STARTS*** */}
      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.Hr,
                ROLES.Manager,
                ROLES.superAdmin,
                ROLES.systemAdmin,
              ]}
            />
          }
        >
          <Route path="candidate" element={<Main />}>
            <Route path="" element={<CandidateModule />}>
              <Route path="candidate-list" element={<CandidateList />} />
              <Route path="create-candidate" element={<CreateCandidate />} />
            </Route>
          </Route>
        </Route>
      </Route>

      {/* CANDIDATE ROUTE ENDS*** */}

      {/* LEAVE ROUTES STARTS*** */}

      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.employee,
                ROLES.Hr,
                ROLES.Manager,
                ROLES.superAdmin,
                ROLES.systemAdmin,
              ]}
            />
          }
        >
          <Route path="leave" element={<Main />}>
            <Route path="" element={<Leaves />}>
              <Route path="my-leave" element={<MyLeave />} />
              <Route
                path="my-leave/:leaveApplicationIdFromParam"
                element={<MyLeave />}
              />
              <Route path="leave-balance" element={<LeaveBalance />} />
              <Route path="holiday-calendar" element={<HolidayCalendar />} />
              <Route path="leave-calendar" element={<LeaveCalendar />} />
              <Route path="employee-leave" element={<EmployeeLeave />} />
              <Route path="settings">
                <Route path="holiday" element={<HolidayList />} />
                <Route
                  path="customize-balance"
                  element={<CustomizeLeaveBalance />}
                />
                <Route
                  path="auto-increment-leave-balance"
                  element={<LeaveBalanceAutoIncrement />}
                />
                <Route path="leave-type" element={<LeaveType />} />
                <Route path="customize-policy" element={<CustomizePolicy />} />
              </Route>
              <Route path="manage-leave">
                <Route path="overview" element={<LeaveOverView />} />
                <Route
                  path="employee-leave"
                  element={<EmployeeLeaveCalendar />}
                />
                {/* <Route
                  path="employee-leave"
                  element={<ManageEmployeeLeave />} //Manage employee leave page contains employee leave details
                /> */}
                <Route
                  path="employee-leave-applications"
                  element={<TaskLeaveApplications />}
                />
                <Route
                  path="employee-leave-applications/:leaveApplicationId"
                  element={<TaskLeaveApplications />}
                />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {/* LEAVE ROUTES ENDS*** */}

      {/* TASK ROUTE STARTS*** */}
      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.employee,
                ROLES.Hr,
                ROLES.Manager,
                ROLES.superAdmin,
                ROLES.systemAdmin,
              ]}
            />
          }
        >
          <Route path="task" element={<Main />}>
            <Route path="" element={<Task />}>
              <Route
                element={
                  <RequireAuth
                    allowedRoles={[
                      ROLES.Hr,
                      ROLES.Manager,
                      ROLES.superAdmin,
                      ROLES.systemAdmin,
                    ]}
                  />
                }
              >
                <Route path="approval">
                  <Route
                    path="leave-applications"
                    element={<TaskLeaveApplications />}
                  />
                  <Route
                    path="policy"
                    element={<PolicyApproval />}
                  />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      {/* TASK ROUTE ENDS*** */}

      {/* EMPLOYEE ROUTES STARTS*** */}

      <Route element={<PersistLogin />}>
        <Route path="employee" element={<Main />}>
          <Route path="" element={<Employee />}>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Hr,
                    ROLES.Manager,
                    ROLES.superAdmin,
                    ROLES.systemAdmin,
                  ]}
                />
              }
            >
              <Route path="overview" element={<EmployeeOverview />} />
              <Route path="manage-employee">
                {/* CREATE EMPLOYEE UI STARTS */}
                <Route path="create-employee" element={<AddEmployeeBasics />} />
                <Route
                  path="employee-personal-details"
                  element={<EmployeePersonalDetails />}
                />
                <Route
                  path="employee-contact-details"
                  element={<EmployeeContactDetails />}
                />
                {/* CREATE EMPLOYEE UI ENDS */}
                <Route path="employee-list" element={<EmployeeList />} />
                <Route
                  path="employee-directory"
                  element={<EmployeeDirectory />}
                />
                <Route
                  path="organization-tree"
                  element={<OrganizationTree />}
                />
                <Route path="seperation" element={<Seperation />} />
              </Route>
              <Route path="recruitment">
                <Route path="talent-pool" element={<TalentPool />} />
                <Route path="job-opening" element={<JobOpening />} />
                <Route path="offer" element={<RecruitmentOffer />} />
              </Route>
            </Route>
          </Route>
          <Route path="employee-details" element={<EmployeeDetailsLayout />}>
            <Route
              path="employee-information/:employeeId"
              element={<SingleEmployeeDetails />}
            />
          </Route>
        </Route>
      </Route>

      {/* EMPLOYEE ROUTES ENDS*** */}

      {/* ASSETS ROUTE STARTS*** */}

      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.employee,
                ROLES.Hr,
                ROLES.Manager,
                ROLES.superAdmin,
                ROLES.systemAdmin,
              ]}
            />
          }
        >
          <Route path="assets" element={<Main />}>
            <Route path="" element={<Assets />}>
              <Route path="my-assets" element={<MyAssets />} />
              <Route path="employee-assets" element={<EmployeeAssets />} />
              <Route path="manage-assets" element={<ManageAssets />} />
            </Route>
          </Route>
        </Route>
      </Route>

      {/*ASSETS ROUTE ENDS*** */}

      {/* ORGANIZATION ROUTE STARTS*** */}

      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.employee,
                ROLES.Hr,
                ROLES.Manager,
                ROLES.superAdmin,
                ROLES.systemAdmin,
              ]}
            />
          }
        >
          <Route path="organization" element={<Main />}>
            <Route path="" element={<Organization />}>
              <Route path="locations" element={<OrganizationLocation />} />
              <Route path="departments" element={<OrganizationDepartment />} />
              <Route
                path="designations"
                element={<OrganizationDesignation />}
              />
            </Route>
          </Route>
        </Route>
      </Route>

      {/* ORGANIZATION ROUTE ENDS*** */}

      {/* PAYROLL ROUTE STARTS*** */}

      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.employee,
                ROLES.Hr,
                ROLES.Manager,
                ROLES.superAdmin,
                ROLES.systemAdmin,
              ]}
            />
          }
        >
          <Route path="payroll" element={<Main />}>
            <Route path="" element={<Payroll />}>
              <Route path="my-payroll">
                <Route path="payslip" element={<Payslip />} />
              </Route>
              <Route path="employee-payroll">
                <Route path="overview" element={<PayrollOverview />} />
              </Route>
              <Route path="settings">
                <Route path="tax" element={<PayrollOrganizationTax />} />
                <Route
                  path="staturary-components"
                  element={<PayrollSaturaryComponents />}
                />
                <Route
                  path="salary-components"
                  element={<PayrollSalaryComponents />}
                />
                <Route
                  path="salary-templates"
                  element={<PayrollSalaryTemplate />}
                />
                <Route
                  path="payment-schedule"
                  element={<PayrollPaymentSchedule />}
                />
                <Route path="preferences" element={<PayrollPreferences />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {/* PAYROLL ROUTE ENDS** */}

      {/* ATTENDANCE ROUTE STARTS*** */}

      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.employee,
                ROLES.Hr,
                ROLES.Manager,
                ROLES.superAdmin,
                ROLES.systemAdmin,
              ]}
            />
          }
        >
          <Route path="attendance" element={<Main />}>
            <Route path="" element={<Attendance />}>
              {/* SELF SERCVE ROUTE */}

              <Route path="self-service">
                <Route path="my-attendance" element={<MyAttendance />} />
                <Route
                  path="my-regulization-request"
                  element={<MyRegularizationRequest />}
                />
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        ROLES.Hr,
                        ROLES.Manager,
                        ROLES.superAdmin,
                        ROLES.systemAdmin,
                      ]}
                    />
                  }
                >
                  <Route path="who-is-in" element={<WhoIsIn />} />
                </Route>

                <Route path="my-shift" element={<MyShifts />} />
              </Route>

              {/* SELF SERCVE ROUTE */}

              <Route
                element={
                  <RequireAuth
                    allowedRoles={[
                      ROLES.Hr,
                      ROLES.Manager,
                      ROLES.superAdmin,
                      ROLES.systemAdmin,
                    ]}
                  />
                }
              >
                {/* MANAGE ATTENDANCE ROUTE */}

                <Route path="manage-attendance">
                  <Route path="overview" element={<Overview />} />
                  <Route
                    path="employee-attendance"
                    element={<EmployeeAttendance />}
                  />
                  <Route
                    path="regularization-approval"
                    element={<RegularizationApproval />}
                  />
                  <Route path="finalize-attendance">
                    <Route path="" element={<FinalizeAttendance />} />
                    <Route
                      path="view-employee-finalize-attendance"
                      element={<ViewEmployeeFinalizeAttendance />}
                    />
                  </Route>
                </Route>
                {/* MANAGE ATTENDANCE ROUTE */}

                {/* MANAGE SHIFT ROUTE */}
                <Route path="manage-shifts">
                  <Route path="shift-lists" element={<ManageShifts />} />
                  <Route path="shift-calendar" element={<ShiftCalendar />} />
                  <Route path="assign-shift" element={<AssignShift />} />
                </Route>
                {/* MANAGE SHIFT ROUTE */}
              </Route>
            </Route>
            <Route
              element={
                <RequireAuth
                  allowedRoles={[
                    ROLES.Hr,
                    ROLES.Manager,
                    ROLES.superAdmin,
                    ROLES.systemAdmin,
                  ]}
                />
              }
            >
              <Route
                path="employee-attendance-details"
                element={<EmployeeDetailsLayout />}
              >
                <Route path="" element={<EmployeeAttendanceDetails />} />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>

      {/* ATTENDANCE ROUTE ENDS*** */}

      {/* SETTING  ROUTE */}
      <Route element={<PersistLogin />}>
        <Route
          element={<RequireAuth allowedRoles={[ROLES.Hr, ROLES.superAdmin]} />}
        >
          <Route path="settings" element={<Main />}>
            <Route path="" element={<SettingsModule />}>
              <Route path="widget-list" element={<WidgetSettings />} />
              <Route path="assign-widget" element={<AssignWidget />} />
              <Route path="tenant-setting" element={<TenantSetting />} />
              <Route path="app-setting" element={<AppSetting />} />
               {/* <Route path="" element={<LeavePolicyCornLogs />} />  */}
              <Route path="leave-policy-renewal-logs" element={ <LeavePolicyCornLogs/>} />
              
              <Route path="leave-auto-increment-logs" element={ <LeaveAutoIncrementLogs/>} />
            </Route>
          </Route>
        </Route>
      </Route>
      {/* SETTING  ENDS*** */}

      {/* POLICY ROUTE STARTS*** */}
      <Route element={<PersistLogin />}>
        <Route
          element={
            <RequireAuth
              allowedRoles={[
                ROLES.employee,
                ROLES.Hr,
                ROLES.Manager,
                ROLES.superAdmin,
                ROLES.systemAdmin,
              ]}
            />
          }
        >
          <Route path="policy" element={<Main />}>
            <Route path="" element={<PolicyModule />}>
              <Route
                element={
                  <RequireAuth
                    allowedRoles={[
                      ROLES.Hr,
                      ROLES.Manager,
                      ROLES.superAdmin,
                      ROLES.systemAdmin,
                      ROLES.employee
                    ]}
                  />
                }
              >
                <Route
                    path="policy-list"
                    element={<PolicyList />}
                  />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      {/* POLICY ROUTE ENDS*** */}
    </Routes>
  );
}

export default App;
