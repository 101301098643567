import React, { useRef, useState } from "react";
import Select, { components } from "react-select";
import Search from "../../Header/Search/Search";
import Pagination from "../../Pagination/Pagination";
import EmptyState from "../../EmptyState/EmptyState";
import userRoleAuth from "../../../utils/userRoleAuth/userRoleAuth";
import userRoles from "../../../config/userRoles";
import { useSelector } from "react-redux";
import {
  useGetLocationList,
  useGetDepartmentList,
  useGetDesignationList,
} from "../../../queries/employeeQueries";
import {
  useGetpolicyRenewalLogData,
  useRenewEmployeeLeavePolicy,
} from "../../../queries/leaveQueries";
import PageLoader from "../../PageLoader/PageLoader";
import LeavePolicyCornTable from "./LeavePolicyCornTable";
import { infoIcon } from "../../../Icons/Icons";
import { toast } from "react-toastify";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <span className="ico-down text-blue-600"></span>
  </components.DropdownIndicator>
);

const DEPARTMENT = "department";
const DESIGNATION = "designation";
const OFFICE = "office";
const FILTER = "filter";
const PAGENO = "pageNo";
const PAGESIZE = "pageSize";
const STATUS = "status";

const LeavePolicyCornLogs = () => {
  const user = useSelector((state) => state.user.auth);
  const isHr = userRoleAuth(user, userRoles.HR);
  const searchRef = useRef();
  const [searchFilter, setFilter] = useState({
    pageNo: 1,
    pageSize: 10,
    filter: "",
    department: "",
    designation: "",
    office: "",
    status: "",
  });
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedOffice, setSelectedOffice] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const isFiltered =
    searchFilter.filter ||
    searchFilter.department ||
    searchFilter.designation ||
    searchFilter.office ||
    searchFilter.status;

  const {
    data: renewalLogData,
    isLoading,
  } = useGetpolicyRenewalLogData(searchFilter);



  const employeeList = renewalLogData?.data || [];
  const totalRecords = renewalLogData?.total || 0;

  const { mutate: renewPolicy, isLoading: isRetryingPolicy } =
    useRenewEmployeeLeavePolicy();

  const handleRetry = () => {
    renewPolicy();
    toast.info("The leave renewal process requires some time. We request you to come back later.")
  };

  const defaultLocationOption = { location_name: "Office: All", id: null };
  const defaultDesignationOption = {
    designation_title: "Designation: All",
    id: null,
  };
  const defaultDepartmentOption = {
    department_name: "Department: All",
    id: null,
  };

  const statusOptions = [
    { label: "Status: All", value: "" },
    { label: "In Progress", value: 1 },
    { label: "Success", value: 2 },
    { label: "Failed", value: 3 },
    { label: "Cancelled", value: 4 },
  ];

  const { data: getLocationList } = useGetLocationList();
  const { data: getDepartmentList } = useGetDepartmentList();
  const { data: getDesignationList } = useGetDesignationList();

  const departmentList = Array.isArray(getDepartmentList?.result)
    ? [defaultDepartmentOption, ...getDepartmentList?.result]
    : [];

  const designationList = Array.isArray(getDesignationList?.result)
    ? [defaultDesignationOption, ...getDesignationList?.result]
    : [];

  const locationList = Array.isArray(getLocationList?.result)
    ? [defaultLocationOption, ...getLocationList?.result]
    : [];

  const handleSetFilter = (value, source) => {
    setFilter((prev) => ({
      ...prev,
      [source]: value,
    }));
  };

  const handleClearFilters = () => {
    setFilter((prev) => ({
      ...prev,
      filter: "",
      department: "",
      designation: "",
      office: "",
      status: "",
    }));

    setSelectedDesignation(null);
    setSelectedDepartment(null);
    setSelectedOffice(null);
    setSelectedStatus(null);

    if (searchRef.current) {
      searchRef.current.value = "";
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const statusCounts = {
    inProgress: 0,
    success: 0,
    failed: 0,
    cancelled: 0,
  };

  if (
    renewalLogData?.LogStatusCount &&
    renewalLogData?.LogStatusCount.length > 0
  ) {
    const log = renewalLogData.LogStatusCount[0];
    statusCounts.inProgress = parseInt(log.inprogress_status) || 0;
    statusCounts.success = parseInt(log.success_status) || 0;
    statusCounts.failed = parseInt(log.failed_status) || 0;
    statusCounts.cancelled = parseInt(log.canceled_status) || 0;
  }

  const handleInfoClick = () => {
    setModalContent(statusCounts);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent("");
  };

  return (
    <>
      <div className="w-full overflow-x-auto">
        <div className="px-10 py-6">
          <div className="flex justify-between items-center pb-4">
            <div>
              <h2>Leave Policy Renewal Logs</h2>
              <p
                className="pb-6 flex items-center gap-2 cursor-pointer"
                onClick={() => handleInfoClick()}
              >
                {isHr ? "Customize and adjust all" : "View"} employee renewal
                log
                <span className="inline-block">{infoIcon}</span>
              </p>
            </div>
            {isModalOpen && (
              <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg p-6 w-1/3 shadow-lg">
                  <h3 className="text-lg font-medium text-gray-700 mb-4">
                    Status Summary
                  </h3>

                  <div className="mb-4">
                    <p>
                      <strong>In Progress:</strong> {modalContent.inProgress}
                    </p>
                    <p>
                      <strong>Success:</strong> {modalContent.success}
                    </p>
                    <p>
                      <strong>Failed:</strong> {modalContent.failed}
                    </p>
                    <p>
                      <strong>Cancelled:</strong> {modalContent.cancelled}
                    </p>
                  </div>

                  <button
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                    onClick={closeModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            )}
            <div className="flex flex-col items-end">
              {statusCounts.failed > 0 && (
                <button
                  className={`btn bg-[#3466E7] max-h-[38px] text-sm mb-2 ${
                    isRetryingPolicy ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  onClick={handleRetry}
                  disabled={isRetryingPolicy}
                >
                  {isRetryingPolicy ? "Retrying..." : "Retry"}
                </button>
              )}
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex gap-3 items-center">
              <Select
                value={selectedDesignation}
                onChange={(selected) => {
                  setSelectedDesignation(selected);
                  handleSetFilter(selected?.value, DESIGNATION);
                }}
                placeholder={"Designation: All"}
                options={designationList.map((i) => ({
                  label: i.designation_title,
                  value: i.id,
                }))}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              <Select
                value={selectedDepartment}
                onChange={(selected) => {
                  setSelectedDepartment(selected);
                  handleSetFilter(selected?.value, DEPARTMENT);
                }}
                placeholder={"Department: All"}
                options={departmentList.map((i) => ({
                  label: i.department_name,
                  value: i.id,
                }))}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              <Select
                value={selectedOffice}
                onChange={(selected) => {
                  setSelectedOffice(selected);
                  handleSetFilter(selected?.value, OFFICE);
                }}
                placeholder={"Office: All"}
                options={locationList.map((i) => ({
                  label: i.location_name,
                  value: i.id,
                }))}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              <Select
                value={selectedStatus}
                onChange={(selected) => {
                  setSelectedStatus(selected);
                  handleSetFilter(selected?.value, STATUS);
                }}
                placeholder={"Status: All"}
                options={statusOptions}
                components={{ DropdownIndicator }}
                className="custom-select-container"
              />
              {isFiltered && (
                <span
                  className="text-blue-800 font-medium cursor-pointer"
                  onClick={handleClearFilters}
                >
                  Clear Filters
                </span>
              )}
            </div>
            <div>
              <Search
                eleRef={searchRef}
                onChange={(e) => handleSetFilter(e, FILTER)}
                value={searchFilter.filter}
              />
            </div>
          </div>
        </div>

        {isLoading ? (
          <PageLoader />
        ) : Array.isArray(employeeList) && employeeList.length ? (
          <>
            <LeavePolicyCornTable employeeList={employeeList} />
            <div className="mt-[65px]">
              <Pagination
                page={{
                  pageNo: searchFilter.pageNo,
                  setPageNo: (e) => handleSetFilter(e, PAGENO),
                  pageSize: searchFilter.pageSize,
                  setPageSize: (e) => handleSetFilter(e, PAGESIZE),
                  totalRecords: totalRecords,
                }}
              />
            </div>
          </>
        ) : (
          <div className="flex items-center justify-center h-screen">
  <strong>Leave renewal is managed by a scheduled cron job. Data can be retrieved after it runs.</strong>
</div>

        )}
      </div>
    </>
  );
};

export default LeavePolicyCornLogs;
