import React from "react";
import CustomizeBalanceListData from "./CustomizeBalanceListData";

const CustomizeBalanceTable = ({ employeeList, leaveTypes, makeApiCall }) => {
  const headerCol = [
    ...[
      {
        colName: "Employee",
      },
    ].concat(leaveTypes.map((i) => ({ colName: i.leave_name }))),
    {
      colName: "Action",
    },
  ];
  return (
    <div className="relative overflow-x-auto">
      <table className="w-full text-sm text-left text-gray-500 dark:text-gray-500">
        <thead className="text-xs font-medium uppercase bg-gray-50 dark:bg-gray-50 text-[#4B5563] border-b border-t dark:border-gray-200 tracking-wider">
          <tr>
            {headerCol.map((col, index) => (
              <th
                scope="col"
                className={`px-10 py-3 text-center border-r ${
                  col.colName === "Action" ? "sticky right-0 bg-gray-50 z-20" : ""
                }`}
                key={index}
              >
                {col.colName}
                {index !== headerCol.length - 1 ? (
                  <i className="ico-down text-blue-600 pl-2.5 cursor-pointer"></i>
                ) : null}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {employeeList.map((val, index) => {
            return (
              <CustomizeBalanceListData
                makeApiCall={makeApiCall}
                leaveTypes={leaveTypes}
                employee={val}
                key={index}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default CustomizeBalanceTable;
