import React, { useState } from "react";
import Search from "../../../../components/Header/Search/Search";
import LeaveTypeListTable from "../../../../components/Leaves/Settings/LeaveType/LeaveTypeListTable";
import AddOrEditLeaveTypeModal from "../../../../components/Leaves/Settings/LeaveType/AddOrEditLeaveTypeModal";
import PageLoader from "../../../../components/PageLoader/PageLoader";
import { useLeaveTypeList } from "../../../../queries/leaveQueries";
import EmptyState from "../../../../components/EmptyState";

const LeaveType = () => {
  const [isAddNewLeaveTypeModal, setIsAddNewLeaveTypeModal] = useState(false);
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const { data: leaveTypes, isLoading, refetch } = useLeaveTypeList({
    status: toggleState,
    fullDetails: 1,
  });

  const leaveTypeData = leaveTypes?.data; 

  return (
    <>
      {isAddNewLeaveTypeModal && (
        <AddOrEditLeaveTypeModal
          openModal={isAddNewLeaveTypeModal}
          onCloseModal={() => setIsAddNewLeaveTypeModal(false)}
        />
      )}

      <div className="w-full">
        <div className="px-10 pt-6 border-b border-gray-300">
          <div className="flex justify-between items-center">
            <div>
              <h2>Leave Type</h2>
              <p className="pb-4">All leave type list</p>
            </div>
            <div>
              <button
                className="btn"
                onClick={() => setIsAddNewLeaveTypeModal(true)}
              >
                <span className="ico-plus"></span> &nbsp; Add New Leave Type
              </button>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center gap-12 pl-4 pt-6">
              <button
                className={
                  toggleState === 1
                    ? "tabs active-tabsnew text-blue-800 font-medium"
                    : "tabs"
                }
                onClick={() => toggleTab(1)}
              >
                Active
              </button>
              <button
                className={
                  toggleState === 0
                    ? "tabs active-tabsnew text-blue-800 font-medium"
                    : "tabs"
                }
                onClick={() => toggleTab(0)}
              >
                Inactive
              </button>
            </div>
            <div className="flex">
              <div>
                <button
                  type="button"
                  className="btn btn--border h-[38px]"
                  onClick={refetch}
                >
                  <span className="material-symbols-outlined">refresh</span>
                </button>
              </div>
              <div>
                <Search />
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <PageLoader />
        ) : leaveTypeData && Array.isArray(leaveTypeData) && leaveTypeData.length > 0 ? (
          <LeaveTypeListTable leaveTypes={leaveTypeData} />
        ) : (
          <EmptyState />
        )}
      </div>
    </>
  );
};

export default LeaveType;
