import React, { useState } from "react";
import useAxiosPrivate from "../../../../hooks/useAxiosPrivate";
import { toast } from "react-toastify";
import LoadingButton from "../../../Buttons/LoadingButton";

const EMPLOYEE_LEAVE_REPORT = "employeeLeaveReport";
const EMPLOYEE_CONSOLIDATE_REPORT = "employeeConsolidateReport";

const LeaveReportDownloadOption = ({ filters, setIsReportDownloadModal }) => {
  const axiosPrivate = useAxiosPrivate();
  const [errMsg, setErrMsg] = useState("");
  const [btnLoader, setBtnLoader] = useState("");
  const [exportAllReports, setExportAllReports] = useState({
    [EMPLOYEE_LEAVE_REPORT]: false,
    [EMPLOYEE_CONSOLIDATE_REPORT]: false,
  });

  const [infoMessages, setInfoMessages] = useState({
    [EMPLOYEE_LEAVE_REPORT]: "Download will happen based on the selected filter and pagination.",
    [EMPLOYEE_CONSOLIDATE_REPORT]: "Download will happen based on the selected filter and pagination.",
  });

  const isEmployeeLeaveReportBtn = btnLoader === EMPLOYEE_LEAVE_REPORT;
  const isEmployeeConsolidatedReportBtn = btnLoader === EMPLOYEE_CONSOLIDATE_REPORT;

  const formatReportUrl = async (baseUrl, filters, exportAll) => {
    let url = baseUrl;
    const queryParams = Object.keys(filters).reduce((acc, key) => {
      if (filters[key] !== undefined && filters[key] !== "" && filters[key] !== null) {
        acc[key] = filters[key];
      }
      return acc;
    }, {});

    if (exportAll) {
      queryParams.exportAll = true;
    }

    const params = Object.keys(queryParams);
    params.forEach((param, index) => {
      url += `${index === 0 ? "?" : "&"}${param}=${queryParams[param]}`;
    });

    return url;
  };

  const handleDownloadReport = async (source) => {
    let baseUrl = "";
    if (source === EMPLOYEE_LEAVE_REPORT) {
      baseUrl = `lms/leave/generateEmployeeLeaveReport`;
    } else {
      baseUrl = `lms/leave/consolidationEmployeeLeaveReport`;
    }

    setBtnLoader(source);

    try {
      const formattedUrl = await formatReportUrl(baseUrl, filters, exportAllReports[source]);

      const response = await axiosPrivate.get(formattedUrl, {
        responseType: "blob", // For downloading files
      });

      if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        const fileName =
          source === EMPLOYEE_LEAVE_REPORT
            ? `employee_leave_report`
            : "consolidation_report";
        link.href = url;
        link.setAttribute("download", `${fileName}.csv`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        toast.success("Report downloaded");
        setIsReportDownloadModal(false);
      } else {
        setErrMsg(response?.data?.message || "Failed to generate report");
      }
    } catch (error) {
      setErrMsg(error?.response?.data?.message || "Failed to generate report");
    } finally {
      setBtnLoader("");
    }
  };

  const handleCheckboxChange = (source) => {
    const isChecked = !exportAllReports[source];
    setExportAllReports((prev) => ({
      ...prev,
      [source]: isChecked,
    }));

    setInfoMessages((prev) => ({
      ...prev,
      [source]: isChecked
        ? "Info: All the data will be exported."
        : "Info: Download will happen based on the selected filter and pagination.",
    }));
  };

  return (
    <div className="flex flex-col gap-1 whitespace-nowrap py-3">
      <span className="font-medium text-red-600">{errMsg}</span>

      {/* Employee Leave Report */}
      <div className="flex flex-col gap-2 border-b p-2">
        <div className="flex justify-between items-center">
          <span className="font-medium text-gray-800">Employee Leave Report</span>
          <div className="flex gap-3 items-center">
            {isEmployeeLeaveReportBtn ? (
              <LoadingButton bg="bg-white" title=" " />
            ) : (
              <button
                onClick={() => handleDownloadReport(EMPLOYEE_LEAVE_REPORT)}
                className="btn btn--border text-[14px]"
              >
                <span>
                  <i className="ico-download"></i>
                </span>
              </button>
            )}
            <div className="flex items-center">
              <label htmlFor="exportAllLeaveReport" className="text-sm mr-2">Export All</label>
              <input
                type="checkbox"
                id="exportAllLeaveReport"
                checked={exportAllReports[EMPLOYEE_LEAVE_REPORT]}
                onChange={() => handleCheckboxChange(EMPLOYEE_LEAVE_REPORT)}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
        <span className="text-sm text-blue-500">{infoMessages[EMPLOYEE_LEAVE_REPORT]}</span>
      </div>

      {/* Employee Consolidation Report */}
      <div className="flex flex-col gap-2 border-b p-2">
        <div className="flex justify-between items-center">
          <span className="font-medium text-gray-800">Employee Consolidation Report</span>
          <div className="flex gap-3 items-center">
            {isEmployeeConsolidatedReportBtn ? (
              <LoadingButton bg="bg-white" title=" " />
            ) : (
              <button
                onClick={() => handleDownloadReport(EMPLOYEE_CONSOLIDATE_REPORT)}
                className="btn btn--border text-[14px]"
              >
                <span>
                  <i className="ico-download"></i>
                </span>
              </button>
            )}
            <div className="flex items-center">
              <label htmlFor="exportAllConsolidateReport" className="text-sm mr-2">Export All</label>
              <input
                type="checkbox"
                id="exportAllConsolidateReport"
                checked={exportAllReports[EMPLOYEE_CONSOLIDATE_REPORT]}
                onChange={() => handleCheckboxChange(EMPLOYEE_CONSOLIDATE_REPORT)}
                className="cursor-pointer"
              />
            </div>
          </div>
        </div>
        <span className="text-sm text-blue-500">{infoMessages[EMPLOYEE_CONSOLIDATE_REPORT]}</span>
      </div>
    </div>
  );
};

export default LeaveReportDownloadOption;
